const initialState = {
  data: [],
  totalPages: 0,
  loadingData: true,
  params: {},
  search: null,
  status: null,
  page: 0,
  allData: [],
  coordinate: [],
  dataListKategoriPembelian: [],
  statusOperation: null,
  dataKolektor: [],
  loadingDataKolektor: true,
  currentPageKolektor: 0,
  totalPagesKolektor: 0,
  selectedPembelian: {},
  dataListItem: [],
  loadingDetailPembelian: true,
  dataAllUser: [],
  loadingDataAllUser: true,
  currentPageAllUser: 0,
  totalPagesAllUser: 0
}

const pembelianReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'POST_URL_SHOWROOM_PEMBELIAN':
      return {
        ...state,
        search: action.search,
        status: action.status,
        page: action.page
      }
    case 'LOADING_DATA_LIST_PEMBELIAN':
      return {
        ...state,
        loadingData: true,
        data: [],
        totalPages: 0
      }
    case 'GET_DATA_LIST_PEMBELIAN':
      return {
        ...state,
        data: action.data,
        totalPages: action.totalPages,
        loadingData: false,
        params: action.params
      }
    case 'LOADING_GET_DETAIL_PEMBELIAN':
      return {
        ...state,
        loadingDetailPembelian: true,
        selectedPembelian: {},
        dataListItem: []
      }
    case 'GET_DETAIL_PEMBELIAN':
      return {
        ...state,
        selectedPembelian: action.selectedPembelian,
        dataListItem: action.dataListItem,
        loadingDetailPembelian: false
      }
    case 'UPDATE_ITEM_DETAIL_DETAIL_PEMBELIAN':
      return {
        ...state,
        dataListItem: action.dataListItem
      }
    case 'GET_COORDINATE':
      return {
        ...state,
        coordinate: action.coordinate
      }
    case 'GET_DATA_LIST_KATEGORI_PEMBELIAN':
      return {
        ...state,
        dataListKategoriPembelian: action.dataListKategoriPembelian
      }
    case 'LOADING_DATA_ALL_USER_RAPEL_PEMBELIAN':
      return {
        ...state,
        loadingDataAllUser: true,
        dataAllUser: [],
        currentPageAllUser: 0,
        totalPagesAllUser: 0
      }
    case 'GET_DATA_ALL_USER_RAPEL_PEMBELIAN':
      return {
        ...state,
        dataAllUser: action.dataAllUser,
        loadingDataAllUser: false,
        currentPageAllUser: action.currentPageAllUser,
        totalPagesAllUser: action.totalPagesAllUser
      }
    case 'ERROR_GET_DATA_LIST_PEMBELIAN':
      return {
        ...state,
        loadingData: false,
        data: [],
        totalPages: 0
      }
    case 'ERROR_GET_DETAIL_PEMBELIAN':
      return {
        ...state,
        selectedPembelian: {},
        dataListItem: [],
        loadingDetailPembelian: false
      }
    case 'ERROR_GET_DATA_LIST_KATEGORI_PEMBELIAN':
      return {
        ...state,
        dataListKategoriPembelian: []
      }
    case 'GET_OPERATIONAL_AREA_PEMBELIAN':
      return {
        ...state,
        statusOperation: action.statusOperation
      }
    case 'ERROR_GET_OPERATIONAL_AREA_PEMBELIAN':
      return {
        ...state,
        statusOperation: null
      }
    case 'ERROR_GET_DATA_ALL_USER_RAPEL_PEMBELIAN':
      return {
        ...state,
        loadingDataAllUser: false,
        dataAllUser: [],
        currentPageAllUser: 0,
        totalPagesAllUser: 0
      }
    case 'DELETE_pembelian':
      return { ...state }
    default:
      return { ...state }
  }
}
export default pembelianReducer
