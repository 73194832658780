// ** Initial State
const initialState = {
  loadingData: true,
  data: [],
  currentPage: 1,
  totalPages: 1,
  params: {},
  id: null,
  selectedErrorLog: {},
  loadingDetailErrorLog: true
}

const errorLog = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING_DETAIL_ERROR_LOG': 
      return {
        ...state,
        loadingDetailErrorLog: true,
        selectedErrorLog: {}
      }
    case 'LOADING_DATA_ERROR_LOG':
      return {
        ...state,
        data: [],
        loadingData: true
      }
    case 'GET_DETAIL_ERROR_LOG':
      return {
        ...state,
        id: action.errorLogId,  
        selectedErrorLog: action.selectedErrorLog,
        loadingDetailErrorLog: false 
      }
    case 'GET_DATA_ERROR_LOG':
      return {
        ...state,
        data: action.data,
        loadingData: false,
        params: action.params,
        id: null,
        currentPage: action.currentPage,
        totalPages: action.totalPages,
        selectedErrorLog: {},
        loadingDetailErrorLog: true
      }
    case 'ERROR_GET_SEARCH_ERROR_LOG':
      return { 
        ...state,
        loadingData: false, 
        data: [] }
    case 'ERROR_GET_ERROR_LOG':
      return { 
        ...state, 
        loadingDetailErrorLog: false }
    default:
      return { ...state }
  }
}
export default errorLog
