// ** Initial State
const initialState = {
  allData: [],
  loadingData: true,
  data: [],
  mitraList: [],
  companyList: [],
  //currentPage: 1,
  totalPages: 1,
  params: {},
  search: null,
  status: null,
  page: 0,
  id: null,
  idposting: null,
  selectedPosting: null,
  loadingDetailPosting: false,
  selectedUser: {},
  activeJobsUser: [],
  postingTransactionUser: [],
  bonusUser: [],
  rapelSaldoTransactionUser: [],
  poinUser: [],
  auditLogUser: [],
  loadingDetailUser: true,
  loadingActiveJobsUser: true,
  loadingPostingTransactionUser: true,
  loadingBonusUser: true,
  loadingRapelSaldoTransactionUser: true,
  loadingPoinUser: true,
  loadingAuditLogUser: true,
  loadingMitraList: true
}

const profileUserRapel = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA_PROFILE_USER_RAPEL':
      return { ...state, allData: action.data, search: null, status: null, page: null }
    case 'LOADING_DETAIL_PROFILE_USER_RAPEL': 
      return {
        ...state,
        selectedUser: {},
        loadingDetailUser: true
      }
    case 'LOADING_TOPUP_PROFILE_USER_RAPEL': 
      return {
        ...state,
        loadingRapelSaldoTransactionUser: true,
        loadingAuditLogUser: true
      }
    case 'LOADING_ADJUSMENT_PROFILE_USER_RAPEL':
      return {
        ...state,
        loadingRapelSaldoTransactionUser: true,
        loadingAuditLogUser: true
      }
    case 'LOADING_DATA_PROFILE_USER_RAPEL':
      return {
        ...state,
        data: [],
        loadingData: true
      }
    case 'ERROR_EDIT_PROFILE_USER_RAPEL':
      return {
        ...state,
        loadingDetailUser: false
      }
    case 'POST_URL_PROFILE_USER_RAPEL':
      return {
        ...state,
        search: action.search,
        status: action.status,
        page: action.page
      }
    case 'GET_DETAIL_PROFILE_USER_RAPEL':
      return {
        ...state,
        loadingData: true,
        //currentPage: 1,
        totalPages: 1,
        id: action.iduser,  
        selectedUser: action.selectedUser,
        loadingDetailUser: false 
      }
    case 'GET_ACTIVEJOBS_PROFILE_USER_RAPEL':
      return {
        ...state,
        id: action.iduser,  
        activeJobsUser: action.activeJobsUser,
        loadingActiveJobsUser: false 
      }
    case 'GET_POSTING_TRANSACTION_PROFILE_USER_RAPEL':
      return {
        ...state,
        id: action.iduser,  
        postingTransactionUser: action.postingTransactionUser,
        loadingPostingTransactionUser: false 
      }
    case 'GET_RAPEL_SALDO_TRANSACTION_PROFILE_USER_RAPEL':
      return {
        ...state,
        id: action.iduser,  
        rapelSaldoTransactionUser: action.rapelSaldoTransactionUser,
        loadingRapelSaldoTransactionUser: false 
      }
    case 'GET_POIN_PROFILE_USER_RAPEL':
      return {
        ...state,
        id: action.iduser,  
        poinUser: action.poinUser,
        loadingPoinUser: false 
      }
    case 'GET_AUDITLOG_PROFILE_USER_RAPEL':
      return {
        ...state,
        id: action.iduser,  
        auditLogUser: action.auditLogUser,
        loadingAuditLogUser: false 
      }
    case 'GET_DATA_MITRA_PROFILE_USER_RAPEL':
      return {
        ...state,
        mitraList: action.mitraList,
        loadingMitraList: false 
      }
    case 'GET_DATA_COMPANY_LIST_ADMIN_PROFILE_USER_RAPEL':
      return {
        ...state,
        companyList: action.dataListCompany
      }
    case 'LOADING_DETAIL_POSTING_PROFILE_USER_RAPEL': 
      return {
        ...state,
        selectedPosting: null,
        idposting: null,
        loadingDetailPosting: true
      }
    case 'GET_DETAIL_POSTING_PROFILE_USER_RAPEL':
      return {
        ...state,
        idposting: action.idposting,  
        selectedPosting: action.selectedPosting,
        loadingDetailPosting: false 
      }
    case 'GET_DATA_PROFILE_USER_RAPEL':
      return {
        ...state,
        data: action.data,
        loadingData: false,
        params: action.params,
        id: null,
        search: null,
        status: null,
        page: null,
        //currentPage: action.currentPage,
        totalPages: action.totalPages,
        selectedUser: {},
        activeJobsUser: [],
        postingTransactionUser: [],
        bonusUser: [],
        rapelSaldoTransactionUser: [],
        poinUser: [],
        auditLogUser: [],
        loadingDetailUser: true,
        loadingActiveJobsUser: true,
        loadingPostingTransactionUser: true,
        loadingBonusUser: true,
        loadingRapelSaldoTransactionUser: true,
        loadingPoinUser: true,
        loadingAuditLogUser: true,
        statusEditData: ''
      }
    case 'ADD_PROFILE_USER_RAPEL':
      return { ...state }
    case 'EDIT_STATUS_PROFILE_USER_RAPEL':
      return { ...state }
    case 'OTP_PROFILE_USER_RAPEL':
      return { ...state }
    case 'EDIT_PROFILE_USER_RAPEL':
      return { ...state }
    case 'EDIT_PASSWORD_PROFILE_USER_RAPEL':
      return { 
        ...state }
    case 'ERROR_GET_SEARCH_PROFILE_USER_RAPEL':
      return { 
        ...state,
        loadingData: false, 
        data: [] }
    case 'ERROR_GET_PROFILE_USER_RAPEL':
      return { 
        ...state, 
        loadingDetailUser: false }
    case 'ERROR_GET_ACTIVEJOBS_PROFILE_USER_RAPEL':
      return { 
        ...state, 
        loadingActiveJobsUser: false }
    case 'ERROR_GET_POSTING_TRANSACTION_PROFILE_USER_RAPEL':
      return { 
        ...state, 
        loadingPostingTransactionUser: false }
    case 'ERROR_GET_RAPEL_SALDO_TRANSACTION_PROFILE_USER_RAPEL':
      return { 
        ...state, 
        loadingRapelSaldoTransactionUser: false }
    case 'ERROR_GET_POIN_PROFILE_USER_RAPEL':
      return { 
        ...state, 
        loadingPoinUser: false }
    case 'ERROR_AUDITLOG_PROFILE_USER_RAPEL':
      return { 
        ...state, 
        loadingAuditLogUser: false }
    case 'ERROR_EDIT_STATUS_PROFILE_USER_RAPEL':
      return { 
        ...state, 
        loadingDetailUser: false }
    case 'ERROR_ADJUSTMENT_PROFILE_USER_RAPEL':
      return { 
        ...state, 
        loadingDetailUser: false,
        loadingRapelSaldoTransactionUser: false,
        loadingAuditLogUser: false }
    case 'ERROR_OTP_PROFILE_USER_RAPEL':
      return { 
        ...state, 
        loadingDetailUser: false }
    case 'ERROR_GET_DETAIL_POSTING_PROFILE_USER_RAPEL':
      return { 
        ...state, 
        loadingDetailPosting: false }
    case 'ERROR_GET_DATA_COMPANY_LIST_ADMIN_PROFILE_USER_RAPEL':
      return {
        ...state,
        companyList: []
      }
    case 'ERROR_UPDATE_COMPANY_PROFILE_USER_RAPEL':
      return { 
        ...state, 
        loadingDetailUser: false }
    default:
      return { ...state }
  }
}
export default profileUserRapel
