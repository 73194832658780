const SUPERADMIN = [
    {
      action: 'manage',
      subject: 'all'
    }
  ]
	
const ADMIN = [
  //Dashboard
  {
    action: 'read',
    subject: 'adminDashboard'
  },
  // {
  //   action: 'read',
  //   subject: 'SummaryAdminRapel'
  // },
  //Showroom
  // {
  //   action: 'read',
  //   subject: 'transaksiShowrooom'
  // },
  //   {
  //     action: 'read',
  //     subject: 'pembelianApp'
  //   },
  //   {
  //     action: 'read',
  //     subject: 'pembelianAppView'
  //   },
  //   {
  //     action: 'read',
  //     subject: 'pembelianAppEdit'
  //   },
  //   {
  //     action: 'read',
  //     subject: 'pembelianAppAdd'
  //   },
  //   {
  //     action: 'read',
  //     subject: 'pembelianAppPrint'
  //   },
	//Buser
    {
      action: 'read',
      subject: 'headerTimBuser'
    },
		{
			action: 'read',
			subject: 'postingListBuser'
		},
  // Rapel Saldo
  {
    action: 'read',
    subject: 'rapelSaldo'
  },
    {
      action: 'read',
      subject: 'rapelSaldoWithdraw'
    },
	//Administrasi
	{
      action: 'read',
      subject: 'administrasi'
    },
			{
				action: 'read',
				subject: 'penggunaApp'
			},
      {
				action: 'read',
				subject: 'allUserApp'
			},
			{
				action: 'read',
				subject: 'penggunaRapelApp'
			},
			{
				action: 'read',
				subject: 'penggunaRapelAppView'
			},
			{
				action: 'read',
				subject: 'kolektorRapelApp'
			},
			{
				action: 'read',
				subject: 'kolektorRapelAppView'
			},
      {
				action: 'read',
				subject: 'rapelBusinessApp'
			},
      {
				action: 'read',
				subject: 'dataCompany'
			},
      {
				action: 'read',
				subject: 'newHoldingCompany'
			},
      {
				action: 'read',
				subject: 'editHoldingCompany'
			},
      {
				action: 'read',
				subject: 'listSchedule'
			},
      {
        action: 'read',
        subject: 'scheduleDetail'
      },
      // Price & Location
      {
        action: 'read',
        subject: 'priceLocationApp'
      },
      {
        action: 'read',
        subject: 'locationPriceList'
      },
      {
        action: 'read',
        subject: 'locationPriceDetail'
      },
      {
        action: 'read',
        subject: 'priceList'
      },
      {
        action: 'read',
        subject: 'priceDetail'
      },
      {
        action: 'read',
        subject: 'priceAdd'
      },
	//Report
		{
			action: 'read',
			subject: 'laporanManagement'
		},
      {
        action: 'read',
        subject: 'lapMapPosting'
      },
      //report Bank Sampah
      /**{
        action: 'read',
        subject: 'lapPostingMitraBisnis'
      },**/
			{
				action: 'read',
				subject: 'lapKeuangan'
			},
			{
				action: 'read',
				subject: 'lapTransaksiRapelApp'
			},
			{
				action: 'read',
				subject: 'lapBalanceRapelSaldo'
			},
			{
				action: 'read',
				subject: 'lapTransaksiRapelSaldo'
			},
      {
        action: 'read',
        subject: 'lapKolektor'
      },
      {
        action: 'read',
        subject: 'lapKolektorBonus'
      },
	//Marketing
		{
			action: 'read',
			subject: 'marketing'
		},
			{
				action: 'read',
				subject: 'beritaApp'
			},
			{
				action: 'read',
				subject: 'beritaAppAdd'
			},
			{
				action: 'read',
				subject: 'beritaAppEdit'
			},
      {
				action: 'read',
				subject: 'pushNotifikasiApp'
			},
      {
				action: 'read',
				subject: 'pushNotifikasiAppAdd'
			},
	//Error Log
		{
			action: 'read',
			subject: 'HeaderLOG'
		},
			{
				action: 'read',
				subject: 'LOG'
			}
  ]

  const MANAGER = [
    // Dashboard
    {
      action: 'read',
      subject: 'adminDashboard'
    },
		//Buser
    {
      action: 'read',
      subject: 'headerTimBuser'
    },
		{
			action: 'read',
			subject: 'postingListBuser'
		},
		//Administrasi
		{
			action: 'read',
			subject: 'administrasi'
		},
			{
				action: 'read',
				subject: 'penggunaApp'
			},
			{
				action: 'read',
				subject: 'penggunaRapelApp'
			},
			{
				action: 'read',
				subject: 'penggunaRapelAppView'
			},
			{
				action: 'read',
				subject: 'kolektorRapelApp'
			},
			{
				action: 'read',
				subject: 'kolektorRapelAppView'
			},
      // Rapel bisnis head menu
      {
				action: 'read',
				subject: 'rapelBusinessApp'
			},
      // Company management
      {
				action: 'read',
				subject: 'dataCompany'
			},
      {
				action: 'read',
				subject: 'newHoldingCompany'
			},
      {
				action: 'read',
				subject: 'editHoldingCompany'
			},
      {
				action: 'read',
				subject: 'listSchedule'
			},
      {
        action: 'read',
        subject: 'scheduleDetail'
      },
      // Price & Location
      {
        action: 'read',
        subject: 'priceLocationApp'
      },
      {
        action: 'read',
        subject: 'locationPriceList'
      },
      {
        action: 'read',
        subject: 'locationPriceDetail'
      },
      {
        action: 'read',
        subject: 'priceList'
      },
      {
        action: 'read',
        subject: 'priceDetail'
      },
      {
        action: 'read',
        subject: 'priceAdd'
      },
		//Report
		{
			action: 'read',
			subject: 'laporanManagement'
		},
      {
        action: 'read',
        subject: 'lapMapPosting'
      },
			{
				action: 'read',
				subject: 'lapKeuangan'
			},
			{
				action: 'read',
				subject: 'lapTransaksiRapelApp'
			},
			{
				action: 'read',
				subject: 'lapBalanceRapelSaldo'
			},
			{
				action: 'read',
				subject: 'lapTransaksiRapelSaldo'
			},
      {
        action: 'read',
        subject: 'lapKolektor'
      },
      {
        action: 'read',
        subject: 'lapKolektorBonus'
      },
      //Marketing
		{
			action: 'read',
			subject: 'marketing'
		},
			{
				action: 'read',
				subject: 'beritaApp'
			},
			{
				action: 'read',
				subject: 'beritaAppAdd'
			},
			{
				action: 'read',
				subject: 'beritaAppEdit'
			},
      {
				action: 'read',
				subject: 'pushNotifikasiApp'
			},
      {
				action: 'read',
				subject: 'pushNotifikasiAppAdd'
			}
  ]

const BUSER = [
    {
			action: 'read',
			subject: 'headerTimBuser'
    },
    {
			action: 'read',
			subject: 'postingListBuser'
    }
	]

const BANKSAMPAH = [
    //Dashboard
    {
      action: 'read',
      subject: 'rabisDashboard'
    },
    {
      action: 'read',
      subject: 'SummaryAdminRapelBisnis'
    },
    //Showroom
  /**{
    action: 'read',
    subject: 'transaksiShowrooom'
  },
    {
      action: 'read',
      subject: 'pembelianApp'
    },
    {
      action: 'read',
      subject: 'pembelianAppView'
    },
    {
      action: 'read',
      subject: 'pembelianAppEdit'
    },
    {
      action: 'read',
      subject: 'pembelianAppAdd'
    },
    {
      action: 'read',
      subject: 'pembelianAppPrint'
    },**/
    //Report
		{
			action: 'read',
			subject: 'laporanManagement'
		},
    //Bank Sampah
      {
        action: 'read',
        subject: 'lapPostingMitraBisnis'
      }
	]

const COMPANYMANAGER = [
  //Dashboard
  {
    action: 'read',
    subject: 'rabisDashboard'
  },
  {
    action: 'read',
    subject: 'SummaryAdminUserRapelBisnis'
  },
  {
    action: 'read',
    subject: 'laporanManagement'
  },
  {
    action: 'read',
    subject: 'lapPurchaseRapelBisnis'
  }
]

const USER = [
  {
      action: 'read',
      subject: 'userProfile'
  },
  {
    action: 'read',
    subject: 'rapelSaldo'
  },
  {
    action: 'read',
    subject: 'userWithdraw'
  }
]

const noAuth = [
    {
        action: 'read',
        subject: 'Auth'
    }
	]

  export const accessControl = type => {
    switch (type) {
			case 'SUPERADMIN' :
				return SUPERADMIN
			case 'ADMIN' :
				return ADMIN
			case 'MANAGER' :
				return MANAGER
			case 'BUSER' :
				return BUSER
      case 'BANK SAMPAH' :
        return BANKSAMPAH
      case 'COMPANYMANAGER' :
        return COMPANYMANAGER
			case 'USER' : 
				return USER
			default:
				return noAuth
    }
  }