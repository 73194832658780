// ** Initial State
const initialState = {
  allData: [],
  data: [],
  dashboardSummary: [],
  dashboardSummaryUserRapelBisnis: [],
  dashboardChartUserRapelBisnis: [],
  dashboardStock: [],
  dataPurchase: [],
  totalPagePurchase: 1,
  pagePurchase: 0,
  dataPurchaseDetail: [],
  loadingPurchaseDetail: true, 
  dashboardSales: [],
  chartPurchase: [],
  chartSales: [],
  mitraBisnisListKolektor: [],
  mitraList: [],
  listCabangPerusahaan: [],
  listScheduleUserRapelBisnis: [],
  //currentPage: 1,
  totalPages: 1,
  params: {},
  search: null,
  status: null,
  page: 0,
  idposting: null,
  activeJobsRapelBisnis: [],
  activeJobsUserRapelBisnis: [],
  selectedPostingDashboard: null,
  loadingDashboardSummary: true,
  loadingDashboardSummaryUserRapelBisnis: true,
  loadingDashboardChartUserRapelBisnis: true,
  loadingActiveJobsRapelBisnis: true,
  loadingActiveJobsUserRapelBisnis: true,
  loadingDetailPostingDashboard: true,
  loadingDashboardStock: true,
  loadingDataPurchase: true,
  loadingDashboardSales: true,
  loadingChart: true,
  loadingMitraBisnisKolektor: true,
  loadingListCabangPerusahaan: true,
  loadingListScheduleUserRapelBisnis: true,
  dataKategori: [],
  loadingDataKategori: true,
  dataDonatChart: []
}

const adminDashboard = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING_DASHBOARD_SUMMARY_RAPEL_BISNIS':
      return {
        ...state, 
        dashboardSummary: [],
        loadingDashboardSummary: true 
      }
    case 'GET_DASHBOARD_SUMMARY_RAPEL_BISNIS':
      return {
        ...state,  
        dashboardSummary: action.dashboardSummary,
        loadingDashboardSummary: false 
      }
    case 'LOADING_DASHBOARD_SUMMARY_USER_RAPEL_BISNIS':
      return {
        ...state, 
        dashboardSummaryUserRapelBisnis: [],
        loadingDashboardSummaryUserRapelBisnis: true 
      }
    case 'GET_DASHBOARD_SUMMARY_USER_RAPEL_BISNIS':
      return {
        ...state,  
        dashboardSummaryUserRapelBisnis: action.dashboardSummaryUserRapelBisnis,
        loadingDashboardSummaryUserRapelBisnis: false 
      }
    case 'LOADING_DASHBOARD_CHART_USER_RAPEL_BISNIS':
      return {
        ...state, 
        dashboardChartUserRapelBisnis: [],
        loadingDashboardChartUserRapelBisnis: true 
      }
    case 'GET_DASHBOARD_CHART_USER_RAPEL_BISNIS':
      return {
        ...state,  
        dashboardChartUserRapelBisnis: action.dashboardChartUserRapelBisnis,
        loadingDashboardChartUserRapelBisnis: false 
      }
    case 'LOADING_DASHBOARD_DONAT_CHART_USER_RAPEL_BISNIS':
      return {
        ...state, 
        dataDonatChart: []
      }
    case 'GET_DASHBOARD_DONAT_CHART_USER_RAPEL_BISNIS':
      return {
        ...state, 
        dataDonatChart: action.dataDonatChart
      }
    case 'LOADING_DASHBOARD_STOCK':
      return {
        ...state, 
        dashboardStock: [],
        loadingDashboardStock: true 
      }
    case 'GET_DASHBOARD_STOCK':
      return {
        ...state,  
        dashboardStock: action.dashboardStock,
        loadingDashboardStock: false 
      }
    case 'LOADING_PURCHASE_LIST':
      return {
        ...state, 
        dataPurchase: [],
        totalPagePurchase: 1,
        pagePurchase: 0,
        loadingDataPurchase: true 
      }
    case 'GET_PURCHASE_LIST':
      return {
        ...state,  
        dataPurchase: action.dataPurchase,
        totalPagePurchase: action.totalPagePurchase,
        pagePurchase: action.pagePurchase,
        loadingDataPurchase: false 
      }
    case 'LOADING_PURCHASE_DETAIL':
      return {
        ...state, 
        dataPurchaseDetail: [],
        loadingPurchaseDetail: true 
      }
    case 'GET_PURCHASE_DETAIL':
      return {
        ...state,  
        dataPurchaseDetail: action.dataPurchaseDetail,
        loadingPurchaseDetail: false 
      }
    case 'LOADING_DASHBOARD_SALES':
      return {
        ...state, 
        dashboardSales: [],
        loadingDashboardSales: true 
      }
    case 'GET_DASHBOARD_SALES':
      return {
        ...state,  
        dashboardSales: action.dashboardSales,
        loadingDashboardSales: false 
      }
    case 'LOADING_CHART_PURCHASE':
      return {
        ...state, 
        chartPurchase: [],
        loadingChart: true 
      }
    case 'GET_CHART_PURCHASE':
      return {
        ...state,  
        chartPurchase: action.chartPurchase,
        loadingChart: false 
      }
    case 'LOADING_CHART_SALES':
      return {
        ...state, 
        chartSales: [],
        loadingChart: true 
      }
    case 'GET_CHART_SALES':
      return {
        ...state,  
        chartSales: action.chartSales,
        loadingChart: false 
      }
    case 'LOADING_MITRA_BISNIS_LIST_KOLEKTOR':
      return {
        ...state, 
        mitraBisnisListKolektor: [],
        loadingMitraBisnisKolektor: true 
      }
    case 'GET_MITRA_BISNIS_LIST_KOLEKTOR':
      return {
        ...state,  
        mitraBisnisListKolektor: action.mitraBisnisListKolektor,
        loadingMitraBisnisKolektor: false 
      }
    case 'LOADING_CABANG_PERUSAHAAN':
      return {
        ...state, 
        listCabangPerusahaan: [],
        loadingListCabangPerusahaan: true 
      }
    case 'GET_CABANG_PERUSAHAAN':
      return {
        ...state,  
        listCabangPerusahaan: action.listCabangPerusahaan,
        loadingListCabangPerusahaan: false 
      }
      case 'LOADING_SCHEDULE_USER_RAPEL_BISNIS':
      return {
        ...state, 
        listScheduleUserRapelBisnis: [],
        loadingListScheduleUserRapelBisnis: true 
      }
    case 'GET_SCHEDULE_USER_RAPEL_BISNIS':
      return {
        ...state,  
        listScheduleUserRapelBisnis: action.listScheduleUserRapelBisnis,
        loadingListScheduleUserRapelBisnis: false 
      }
    case 'LOADING_ACTIVEJOBS_RAPEL_BISNIS':
      return {
        ...state, 
        activeJobsRapelBisnis: [],
        loadingActiveJobsRapelBisnis: true 
      }
    case 'GET_ACTIVEJOBS_RAPEL_BISNIS':
      return {
        ...state,
        activeJobsRapelBisnis: action.activeJobsRapelBisnis,
        loadingActiveJobsRapelBisnis: false 
      }
      case 'LOADING_ACTIVEJOBS_USER_RAPEL_BISNIS':
      return {
        ...state, 
        activeJobsUserRapelBisnis: [],
        loadingActiveJobsUserRapelBisnis: true 
      }
    case 'GET_ACTIVEJOBS_USER_RAPEL_BISNIS':
      return {
        ...state,
        activeJobsUserRapelBisnis: action.activeJobsUserRapelBisnis,
        loadingActiveJobsUserRapelBisnis: false 
      }
    case 'LOADING_DETAIL_POSTING_DASHBOARD': 
      return {
        ...state,
        selectedPostingDashboard: null,
        idposting: null,
        loadingDetailPostingDashboard: true
      }
    case 'GET_DETAIL_POSTING_DASHBOARD':
      return {
        ...state,
        idposting: action.idposting,  
        selectedPostingDashboard: action.selectedPostingDashboard,
        loadingDetailPostingDashboard: false 
      }
    case 'ERROR_GET_DASHBOARD_SUMMARY_RAPEL_BISNIS':
      return { 
        ...state, 
        dashboardSummary: [],
        loadingDashboardSummary: false }
    case 'ERROR_GET_DASHBOARD_SUMMARY_USER_RAPEL_BISNIS':
      return {
        ...state, 
        dashboardSummaryUserRapelBisnis: [],
        loadingDashboardSummaryUserRapelBisnis: false 
      }
    case 'ERROR_GET_DASHBOARD_CHART_USER_RAPEL_BISNIS':
      return {
        ...state, 
        dashboardChartUserRapelBisnis: [],
        loadingDashboardChartUserRapelBisnis: false 
      }
    case 'ERROR_GET_DASHBOARD_STOCK':
      return { 
        ...state, 
        dashboardStock: [],
        loadingDashboardStock: false }
    case 'ERROR_GET_PURCHASE_LIST':
      return { 
        ...state, 
        dataPurchase: [],
        totalPagePurchase: 1,
        pagePurchase: 0,
        loadingDataPurchase: false }
    case 'ERROR_get_PURCHASE_DETAIL':
      return {
        ...state, 
        dataPurchaseDetail: [],
        loadingPurchaseDetail: false 
      }
    case 'ERROR_GET_DASHBOARD_SALES':
      return { 
        ...state, 
        dashboardSales: [],
        loadingDashboardSales: false }
    case 'ERROR_GET_CHART_PURCHASE':
      return { 
        ...state, 
        chartPurchase: [],
        loadingChart: false }
    case 'ERROR_GET_CHART_SALES':
      return { 
        ...state, 
        chartSales: [],
        loadingChart: false }
    case 'ERROR_GET_MITRA_BISNIS_LIST_KOLEKTOR':
      return {
        ...state,  
        mitraBisnisListKolektor: action.mitraBisnisListKolektor,
        loadingMitraBisnisKolektor: false 
      }
    case 'ERROR_CABANG_PERUSAHAAN':
      return {
        ...state,  
        listCabangPerusahaan: [],
        loadingListCabangPerusahaan: false 
      }
    case 'ERROR_GET_SCHEDULE_USER_RAPEL_BISNIS':
      return {
        ...state,  
        listScheduleUserRapelBisnis: [],
        loadingListScheduleUserRapelBisnis: false 
      }
    case 'ERROR_GET_ACTIVEJOBS_RAPEL_BISNIS':
      return { 
        ...state, 
        loadingActiveJobsRapelBisnis: false }
    case 'ERROR_GET_ACTIVEJOBS_USER_RAPEL_BISNIS':
      return { 
        ...state, 
        loadingActiveJobsUserRapelBisnis: false }
    case 'ERROR_GET_DETAIL_POSTING_DASHBOARD':
      return { 
        ...state, 
        selectedPostingDashboard: null,
        loadingDetailPostingDashboard: false }
    case 'LOADING_DATA_KATEGORI_LIST_PRICE_USER_RAPEL_BISNIS':
      return {
        ...state,
        dataKategori: [],
        loadingDataKategori: true
      }
    case 'GET_DATA_KATEGORI_LIST_PRICE_USER_RAPEL_BISNIS':
      return {
        ...state,
        dataKategori: action.dataKategori,
        loadingDataKategori: false
      }
    case 'ERROR_GET_DATA_KATEGORI_LIST_PRICE_USER_RAPEL_BISNIS':
      return {
        ...state, 
        dataKategori: [],
        loadingDataKategori: false
      }
    default:
      return { ...state }
  }
}
export default adminDashboard
