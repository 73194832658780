// ** Initial State
const initialState = {
  loadingData: true,
  loadingDataCompany: true,
  loadingDataLocation: true,
  loadingDataKategori: true,
  loadingAdd: false,
  data: [],
  dataCompany: [],
  dataLocation: [],
  dataKategori: [],
  tmpInputPriceGroupName: '',
  tmpInputPrice: [],
  defaultPrice: [],
  defaultCompany: [],
  defaultLocation: [],
  //currentPage: 1,
  totalPages: 1,
  params: {},
  search: null,
  status: null,
  page: 0,
  selectedPrice: {},
  loadingDetailPrice: true
}

const priceReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING_DETAIL_PRICE_RAPEL': 
      return {
        ...state,
        selectedPrice: {},
        defaultPrice: [],
        defaultCompany: [],
        defaultLocation: [],
        tmpChangePrice: [],
        loadingDetailPrice: true
      }
    case 'LOADING_UPDATE_DETAIL_PRICE_RAPEL': 
      return {
        ...state,
        loadingDetailPrice: true
      }
    case 'LOADING_DATA_PRICE_RAPEL':
      return {
        ...state,
        dataCompany: [],
        dataLocation: [],
        dataKategori: [],
        tmpInputPriceGroupName: '',
        tmpInputPrice: [],
        selectedPrice: {},
        loadingData: true
      }
    case 'GET_DATA_PRICE_RAPEL':
      return {
        ...state,
        totalPages: action.totalPages, 
        data: action.data,
        loadingData: false
      }
    case 'ERROR_GET_DATA_PRICE_RAPEL':
      return {
        ...state, 
        data: [],
        loadingData: false
      }
    case 'LOADING_DATA_COMPANY_LIST_PRICE_RAPEL':
      return {
        ...state,
        dataCompany: [],
        loadingDataCompany: true
      }
    case 'GET_DATA_COMPANY_LIST_PRICE_RAPEL':
      return {
        ...state,
        dataCompany: action.dataCompany,
        loadingDataCompany: false
      }
    case 'ERROR_GET_DATA_COMPANY_LIST_PRICE_RAPEL':
      return {
        ...state, 
        dataCompany: [],
        loadingDataCompany: false
      }
    case 'LOADING_DATA_LOCATION_LIST_PRICE_RAPEL':
      return {
        ...state,
        dataLocation: [],
        loadingDataLocation: true
      }
    case 'GET_DATA_LOCATION_LIST_PRICE_RAPEL':
      return {
        ...state,
        dataLocation: action.dataLocation,
        loadingDataLocation: false
      }
    case 'ERROR_GET_DATA_LOCATION_LIST_PRICE_RAPEL':
      return {
        ...state, 
        dataLocation: [],
        loadingDataLocation: false
      }
      case 'LOADING_DATA_KATEGORI_LIST_PRICE_RAPEL':
      return {
        ...state,
        dataKategori: [],
        loadingDataKategori: true
      }
    case 'GET_DATA_KATEGORI_LIST_PRICE_RAPEL':
      return {
        ...state,
        dataKategori: action.dataKategori,
        loadingDataKategori: false
      }
    case 'ERROR_GET_DATA_KATEGORI_LIST_PRICE_RAPEL':
      return {
        ...state, 
        dataKategori: [],
        loadingDataKategori: false
      }
    case 'POST_URL_PRICE_RAPEL':
      return {
        ...state,
        search: action.search,
        status: action.status,
        page: action.page
      }
    case 'GET_DETAIL_PRICE_RAPEL':
      return {
        ...state,
        tmpInputPriceGroupName: action.tmpInputPriceGroupName,
        selectedPrice: action.selectedPrice,
        defaultPrice: action.defaultPrice,
        defaultCompany: action.defaultCompany,
        defaultLocation: action.defaultLocation,
        loadingDetailPrice: false 
      }
    case 'LOADING_ADD_PRICE_RAPEL':
      return {
        ...state,
        loadingAdd: true
      }
    case 'STOP_LOADING_ADD_PRICE_RAPEL':
      return {
        ...state,
        loadingAdd: false
      }
    case 'ADD_PRICE_RAPEL':
      return {
        ...state,
        tmpInputPriceGroupName: action.tmpInputPriceGroupName,
        tmpInputPrice: action.tmpInputPrice
      }
    case 'ERROR_GET_DETAIL_PRICE_RAPEL':
      return {
        ...state,
        selectedPrice: {},
        defaultPrice: [],
        defaultCompany: [],
        defaultLocation: [],
        tmpChangePrice: [],
        loadingDetailPrice: false 
      }
    case 'STOP_LOADING_UPDATE_PRICE_RAPEL':
      return {
        ...state,
        loadingDetailPrice: false
      }
    default:
      return { ...state }
  }
}
export default priceReducer
