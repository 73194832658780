import mock from '../mock'
import { paginateArray } from '../utils'

const data = {
  kategoris: [
    {
      idkategoriItem: 1,
      kategoriItemName: 'Arsip HVS',
      kategoriItemSatuan: 'Kg',
      kategoriItemHarga: '800',
      idkategoriInduk: 'Kertas'
    },
    {
      idkategoriItem: 2,
      kategoriItemName: 'Duplex',
      kategoriItemSatuan: 'Kg',
      kategoriItemHarga: '450',
      idkategoriInduk: 'Kertas'
    },
    {
      idkategoriItem: 3,
      kategoriItemName: 'Kantong Semen',
      kategoriItemSatuan: 'Kg',
      kategoriItemHarga: '700',
      idkategoriInduk: 'Kertas'
    },
    {
      idkategoriItem: 4,
      kategoriItemName: 'Kardus',
      kategoriItemSatuan: 'Kg',
      kategoriItemHarga: '900',
      idkategoriInduk: 'Kertas'
    },
    {
      idkategoriItem: 5,
      kategoriItemName: 'tetrapak',
      kategoriItemSatuan: 'Kg',
      kategoriItemHarga: '100',
      idkategoriInduk: 'Kertas'
    },
    {
      idkategoriItem: 6,
      kategoriItemName: 'Kertas Buram',
      kategoriItemSatuan: 'Kg',
      kategoriItemHarga: '700',
      idkategoriInduk: 'Kertas'
    },
    {
      idkategoriItem: 7,
      kategoriItemName: 'Koran',
      kategoriItemSatuan: 'Kg',
      kategoriItemHarga: '1050',
      idkategoriInduk: 'Kertas'
    },
    {
      idkategoriItem: 8,
      kategoriItemName: 'CD bekas',
      kategoriItemSatuan: 'Kg',
      kategoriItemHarga: '1000',
      idkategoriInduk: 'Plastik'
    },
    {
      idkategoriItem: 9,
      kategoriItemName: 'Galon Air',
      kategoriItemSatuan: 'Kg',
      kategoriItemHarga: '1200',
      idkategoriInduk: 'Plastik'
    },
    {
      idkategoriItem: 10,
      kategoriItemName: 'PET Botol',
      kategoriItemSatuan: 'Kg',
      kategoriItemHarga: '400',
      idkategoriInduk: 'Plastik'
    },
    {
      idkategoriItem: 11,
      kategoriItemName: 'PET Botol Warna',
      kategoriItemSatuan: 'Kg',
      kategoriItemHarga: '300',
      idkategoriInduk: 'Plastik'
    },
    {
      idkategoriItem: 12,
      kategoriItemName: 'Plastik HDPE',
      kategoriItemSatuan: 'Kg',
      kategoriItemHarga: '900',
      idkategoriInduk: 'Plastik'
    },
    {
      idkategoriItem: 13,
      kategoriItemName: 'Platik Kerasan',
      kategoriItemSatuan: 'Kg',
      kategoriItemHarga: '100',
      idkategoriInduk: 'Plastik'
    },
    {
      idkategoriItem: 14,
      kategoriItemName: 'Plastik Hitam',
      kategoriItemSatuan: 'Kg',
      kategoriItemHarga: '200',
      idkategoriInduk: 'Plastik'
    },
    {
      idkategoriItem: 15,
      kategoriItemName: 'PP Gelas',
      kategoriItemSatuan: 'Kg',
      kategoriItemHarga: '1600',
      idkategoriInduk: 'Plastik'
    },
    {
      idkategoriItem: 16,
      kategoriItemName: 'PVC',
      kategoriItemSatuan: 'Kg',
      kategoriItemHarga: '160',
      idkategoriInduk: 'Plastik'
    },
    {
      idkategoriItem: 17,
      kategoriItemName: 'Aluminium',
      kategoriItemSatuan: 'Kg',
      kategoriItemHarga: '3550',
      idkategoriInduk: 'Logam'
    },
    {
      idkategoriItem: 18,
      kategoriItemName: 'Besi',
      kategoriItemSatuan: 'Kg',
      kategoriItemHarga: '1250',
      idkategoriInduk: 'Logam'
    },
    {
      idkategoriItem: 19,
      kategoriItemName: 'Kaleng',
      kategoriItemSatuan: 'Kg',
      kategoriItemHarga: '550',
      idkategoriInduk: 'Logam'
    },
    {
      idkategoriItem: 20,
      kategoriItemName: 'Kuningan',
      kategoriItemSatuan: 'Kg',
      kategoriItemHarga: '9000',
      idkategoriInduk: 'Logam'
    },
    {
      idkategoriItem: 21,
      kategoriItemName: 'Tembaga',
      kategoriItemSatuan: 'Kg',
      kategoriItemHarga: '15000',
      idkategoriInduk: 'Logam'
    }
  ]
}

// GET ALL DATA
mock.onGet('/api/kategoris/list/all-data').reply(200, data.kategoris)

// POST: Add new kategori
mock.onPost('/apps/kategoris/add-kategori').reply(config => {
  // Get event from post data
  const kategori = JSON.parse(config.data)

  const { length } = data.kategoris
  let lastIndex = 0
  if (length) {
    lastIndex = data.kategoris[length - 1].id
  }
  kategori.id = lastIndex + 1

  data.kategoris.unshift(kategori)

  return [201, { kategori }]
})

// GET Updated DATA
mock.onGet('/api/kategoris/list/data').reply(config => {
  const { q = '', perPage = 10, page = 1, role = null, currentPlan = null, status = null } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()
  const filteredData = data.kategoris.filter(
    kategori =>
      (kategori.kategoriname.toLowerCase().includes(queryLowered) || kategori.fullName.toLowerCase().includes(queryLowered)) &&
      kategori.role === (role || kategori.role) &&
      kategori.currentPlan === (currentPlan || kategori.currentPlan) &&
      kategori.status === (status || kategori.status)
  )
  /* eslint-enable  */

  return [
    200,
    {
      kategoris: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

// GET kategori
mock.onGet('/api/kategoris/kategori').reply(config => {
  const { id } = config
  const kategori = data.kategoris.find(i => i.id === id)
  return [200, { kategori }]
})

// DELETE: Deletes kategori
mock.onDelete('/apps/kategoris/delete').reply(config => {
  // Get kategori id from URL
  let kategoriId = config.id

  // Convert Id to number
  kategoriId = Number(kategoriId)

  const kategoriIndex = data.kategoris.findIndex(t => t.id === kategoriId)
  data.kategoris.splice(kategoriIndex, 1)

  return [200]
})