// ** Initial State
const initialState = {
  allData: [],
  loadingData: true,
  data: [],
  mitraList: [],
  //currentPage: 1,
  totalPages: 1,
  params: {},
  search: null,
  status: null,
  page: 0,
  id: null,
  idposting: null,
  selectedPosting: null,
  loadingDetailPosting: false,
  selectedKolektor: {},
  activeJobsKolektor: [],
  postingTransactionKolektor: [],
  bonusKolektor: [],
  rapelSaldoTransactionKolektor: [],
  poinKolektor: [],
  auditLogKolektor: [],
  loadingDetailKolektor: true,
  loadingActiveJobsKolektor: true,
  loadingPostingTransactionKolektor: true,
  loadingBonusKolektor: true,
  loadingRapelSaldoTransactionKolektor: true,
  loadingPoinKolektor: true,
  loadingAuditLogKolektor: true,
  loadingMitraList: true
}

const kolektor = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA_KOLEKTOR_RAPEL':
      return { ...state, allData: action.data, search: null, status: null, page: null }
    case 'LOADING_DETAIL_KOLEKTOR_RAPEL': 
      return {
        ...state,
        selectedKolektor: {},
        loadingDetailKolektor: true
      }
    case 'LOADING_TOPUP_KOLEKTOR_RAPEL': 
      return {
        ...state,
        loadingRapelSaldoTransactionKolektor: true,
        loadingAuditLogKolektor: true
      }
    case 'LOADING_ADJUSMENT_KOLEKTOR_RAPEL':
      return {
        ...state,
        loadingRapelSaldoTransactionKolektor: true,
        loadingAuditLogKolektor: true
      }
    case 'LOADING_DATA_KOLEKTOR_RAPEL':
      return {
        ...state,
        data: [],
        loadingData: true
      }
    case 'ERROR_EDIT_KOLEKTOR_RAPEL':
      return {
        ...state,
        loadingDetailKolektor: false
      }
    case 'POST_URL_KOLEKTOR_RAPEL':
      return {
        ...state,
        search: action.search,
        status: action.status,
        page: action.page
      }
    case 'GET_DETAIL_KOLEKTOR_RAPEL':
      return {
        ...state,
        loadingData: true,
        //currentPage: 1,
        totalPages: 1,
        id: action.iduser,  
        selectedKolektor: action.selectedKolektor,
        loadingDetailKolektor: false 
      }
    case 'GET_ACTIVEJOBS_KOLEKTOR_RAPEL':
      return {
        ...state,
        id: action.iduser,  
        activeJobsKolektor: action.activeJobsKolektor,
        loadingActiveJobsKolektor: false 
      }
    case 'GET_POSTING_TRANSACTION_KOLEKTOR_RAPEL':
      return {
        ...state,
        id: action.iduser,  
        postingTransactionKolektor: action.postingTransactionKolektor,
        loadingPostingTransactionKolektor: false 
      }
    case 'GET_BONUS_KOLEKTOR_RAPEL':
      return {
        ...state,
        id: action.iduser,  
        bonusKolektor: action.bonusKolektor,
        loadingBonusKolektor: false 
      }
    case 'GET_RAPEL_SALDO_TRANSACTION_KOLEKTOR_RAPEL':
      return {
        ...state,
        id: action.iduser,  
        rapelSaldoTransactionKolektor: action.rapelSaldoTransactionKolektor,
        loadingRapelSaldoTransactionKolektor: false 
      }
    case 'GET_POIN_KOLEKTOR_RAPEL':
      return {
        ...state,
        id: action.iduser,  
        poinKolektor: action.poinKolektor,
        loadingPoinKolektor: false 
      }
    case 'GET_AUDITLOG_KOLEKTOR_RAPEL':
      return {
        ...state,
        id: action.iduser,  
        auditLogKolektor: action.auditLogKolektor,
        loadingAuditLogKolektor: false 
      }
    case 'GET_DATA_MITRA_KOLEKTOR_RAPEL':
      return {
        ...state,
        mitraList: action.mitraList,
        loadingMitraList: false 
      }
    case 'LOADING_DETAIL_POSTING_KOLEKTOR_RAPEL': 
      return {
        ...state,
        selectedPosting: null,
        idposting: null,
        loadingDetailPosting: true
      }
    case 'GET_DETAIL_POSTING_KOLEKTOR_RAPEL':
      return {
        ...state,
        idposting: action.idposting,  
        selectedPosting: action.selectedPosting,
        loadingDetailPosting: false 
      }
    case 'GET_DATA_KOLEKTOR_RAPEL':
      return {
        ...state,
        data: action.data,
        loadingData: false,
        params: action.params,
        id: null,
        search: null,
        status: null,
        page: null,
        //currentPage: action.currentPage,
        totalPages: action.totalPages,
        selectedKolektor: {},
        activeJobsKolektor: [],
        postingTransactionKolektor: [],
        bonusKolektor: [],
        rapelSaldoTransactionKolektor: [],
        poinKolektor: [],
        auditLogKolektor: [],
        loadingDetailKolektor: true,
        loadingActiveJobsKolektor: true,
        loadingPostingTransactionKolektor: true,
        loadingBonusKolektor: true,
        loadingRapelSaldoTransactionKolektor: true,
        loadingPoinKolektor: true,
        loadingAuditLogKolektor: true,
        statusEditData: ''
      }
    case 'ADD_KOLEKTOR_RAPEL':
      return { ...state }
    case 'EDIT_STATUS_KOLEKTOR_RAPEL':
      return { ...state }
    case 'OTP_KOLEKTOR_RAPEL':
      return { ...state }
    case 'EDIT_KOLEKTOR_RAPEL':
      return { ...state }
    case 'EDIT_PASSWORD_KOLEKTOR_RAPEL':
      return { 
        ...state }
    case 'ERROR_GET_SEARCH_KOLEKTOR_RAPEL':
      return { 
        ...state,
        //loadingData: false,
        data: [] }
    case 'ERROR_GET_KOLEKTOR_RAPEL':
      return { 
        ...state, 
        loadingDetailKolektor: false }
    case 'ERROR_GET_ACTIVEJOBS_KOLEKTOR_RAPEL':
      return { 
        ...state, 
        loadingActiveJobsKolektor: false }
    case 'ERROR_GET_POSTING_TRANSACTION_KOLEKTOR_RAPEL':
      return { 
        ...state, 
        loadingPostingTransactionKolektor: false }
    case 'ERROR_GET_BONUS_KOLEKTOR_RAPEL':
      return { 
        ...state, 
        loadingBonusKolektor: false }
    case 'ERROR_GET_RAPEL_SALDO_TRANSACTION_KOLEKTOR_RAPEL':
      return { 
        ...state, 
        loadingRapelSaldoTransactionKolektor: false }
    case 'ERROR_GET_POIN_KOLEKTOR_RAPEL':
      return { 
        ...state, 
        loadingPoinKolektor: false }
    case 'ERROR_AUDITLOG_KOLEKTOR_RAPEL':
      return { 
        ...state, 
        loadingAuditLogKolektor: false }
    case 'ERROR_EDIT_STATUS_KOLEKTOR_RAPEL':
      return {
        ...state,
        loadingDetailKolektor: false,
        loadingAuditLogKolektor: false
      }
    case 'ERROR_OTP_KOLEKTOR_RAPEL':
      return { 
        ...state, 
        loadingDetailKolektor: false }
    case 'ERROR_GET_DETAIL_POSTING_KOLEKTOR_RAPEL':
      return { 
        ...state, 
        loadingDetailPosting: false }
    case 'ERROR_TOPUP_KOLEKTOR_RAPEL':
      return { 
        ...state, 
        loadingRapelSaldoTransactionKolektor: false,
        loadingAuditLogKolektor: false }
    case 'ERROR_ADJUSTMENT_KOLEKTOR_RAPEL':
      return { 
        ...state, 
        loadingRapelSaldoTransactionKolektor: false,
        loadingAuditLogKolektor: false }
    default:
      return { ...state }
  }
}
export default kolektor
