// ** Initial State
const initialState = {
  alldataMapPosting: [],
  loadingData: true,
  data: [],
  dataKolektor: [],
  currentPage: 1,
  totalPages: 1,
  params: {},
  id: null,
  selectedMapPosting: null,
  loadingDetailPostingBuser: false
}

const expand = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA_MAP_POSTING':
      return { ...state, alldataMapPosting: action.data, loadingData: false }
    case 'LOADING_DETAIL_MAP_POSTING': 
      return {
        ...state,
        selectedMapPosting: null,
        id: null,
        loadingDetailPostingBuser: true
      }
    case 'LOADING_DATA_MAP_POSTING':
      return {
        ...state,
        alldataMapPosting: [],
        loadingData: true
      }
    case 'GET_DETAIL_MAP_POSTING':
      return {
        ...state,
        id: action.idposting,  
        selectedMapPosting: action.selectedMapPosting,
        loadingDetailPostingBuser: false 
      }
    case 'GET_DATA_MAP_POSTING':
      return {
        ...state,
        data: action.data,
        loadingData: false,
        params: action.params,
        currentPage: action.currentPage,
        totalPages: action.totalPages
      }
    case 'GET_DATA_KOLEKTOR_RAPEL_MAP_POSTING':
      return {
        ...state,
        dataKolektor: action.dataKolektor
      }
    case 'ERROR_GET_All_DATA_MAP_POSTING':
      return {
        ...state,
        alldataMapPosting: [],
        loadingData: false
      }
    case 'ERROR_GET_DATA_MAP_POSTING':
      return { 
        ...state, 
        data: [],
        loadingData: false }
    case 'ERROR_GET_DETAIL_MAP_POSTING':
      return { 
        ...state,
        selectedMapPosting: null,
        loadingDetailPostingBuser: false }
    default:
      return { ...state }
  }
}
export default expand
