// ** Initial State
const initialState = {
  selectedPrice: {},
  loadingDetailPrice: true
}

const defaultPriceReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING_PRICE_DEFAULT_COMPANY_RAPEL': 
      return {
        ...state,
        selectedPrice: {},
        loadingDetailPrice: true
      }
    case 'GET_PRICE_DEFAULT_COMPANY_RAPEL':
      return {
        ...state,
        selectedPrice: action.selectedPrice,
        loadingDetailPrice: false 
      }
    case 'ERROR_GET_PRICE_DEFAULT_COMPANY_RAPEL':
      return {
        ...state,
        selectedPrice: {},
        loadingDetailPrice: false 
      }
    default:
      return { ...state }
  }
}
export default defaultPriceReducer
