import { useState } from 'react'
import mock from '../mock'
import { paginateArray, sortCompare } from '../utils'
import ListKabupaten from './IDN_adm_2_kabkota.json'
import ListKelurahan from './daftar_isi_kelurahan.json'

const regencyList = ListKabupaten.features
let dataVillage = null

const fetchVillageData = async (value) => {
  dataVillage = null
  try {
    const myfile = await fetch(`/villages/${value}`)
    dataVillage = await myfile.json()
    return dataVillage
  } catch (error) {
    console.log(error)
  }
}

const getAdministrationVillage = async (villageName, subDistrictName, regionName) => {

  let result = []

  const filteredData = ListKelurahan?.filter(
    (f) => f.village === villageName.toUpperCase() && f.sub_district === subDistrictName.toUpperCase() && f.district === regionName.toUpperCase()
  )

  if (filteredData.length > 0) {
    result = await fetchVillageData(filteredData[0].filename)
  }

  return result
}

// ------------------------------------------------
// GET: Return Single kabupaten
// ------------------------------------------------
mock.onGet('/kabupaten/select').reply((config) => {
  const { name, type } = config
  let resultFilter = []

  if (name !== undefined && name !== '' && name !== null) {
    const getData = regencyList.filter(
      (f) => f.properties.NAME_2.includes(name) && f.properties.TYPE_2 === type
    )
    resultFilter = getData
  }

  const responseData = {
    data: resultFilter
  }

  return [200, responseData]
})

mock.onGet('/village/select').reply(async (config) => {
  const { villageName, subDistrictName, regionName } = config
  let resultFilter = []

  if (villageName !== undefined && villageName !== '' && villageName !== null) {
    await getAdministrationVillage(villageName, subDistrictName, regionName)
    .then((result) => {
      resultFilter = result.features
    })
    .catch((error) => {
      console.log(error)
    })
  }

  const responseData = {
    data: resultFilter
  }

  return [200, responseData]
})
