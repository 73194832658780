// ** Initial State
const initialState = {
  header: null,
  text: false,
  back: false,
  page: 1,
  search: null,
  status: null,
  url: null
}

const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_HEADER':
      return { ...state, header: action.header, text: action.text, back: action.back }
    case 'SET_PARAMS':
      return { ...state, search: action.search, status: action.status, page: action.page, url: action.url }
    default:
      return state
  }
}

export default menuReducer
