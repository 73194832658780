// ** Initial State
const initialState = {
  globalSetting: {
    adminFeeInPerc: 10,
    adminFeeInPercGreaterThanOrEqual10KG: 10,
    adminFeeInPercLessThan10KG: 15,
    minWithdrawal: 0,
    withdrawalFee: 0,
    otpExpiryInSecond: 300
  },
  rapelSaldoTransactionUser: [],
  loadingRapelSaldo: true,
  selectedUser: {},
  isInside: true,
  otp: null,
  loadingOtp: true,
  loadingWithdraw: false
}

const withdrawUser = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DATA_GLOBAL_SETTING_WITHDRAW_USER':
      return {
        ...state,
        globalSetting: action.globalSetting
      }
    case 'LOADING_RAPEL_SALDO_TRANSACTION_WITHDRAW_USER':
      return {
        ...state,
        rapelSaldoTransactionUser: [],
        loadingRapelSaldo: true
      }
    case 'GET_RAPEL_SALDO_TRANSACTION_WITHDRAW_USER':
      return {
        ...state,
        rapelSaldoTransactionUser: action.rapelSaldoTransactionUser,
        loadingRapelSaldo: false
      }
    case 'ERROR_GET_RAPEL_SALDO_TRANSACTION_WITHDRAW_USER':
      return {
        ...state,
        rapelSaldoTransactionUser: [],
        loadingRapelSaldo: false
      }
    case 'LOADING_DETAIL_PROFILE_WITHDRAW_USER':
      return {
        ...state,
        selectedUser: [],
        loadingRapelSaldo: true
      }
    case 'GET_DETAIL_PROFILE_WITHDRAW_USER':
      return {
        ...state,
        selectedUser: action.selectedUser,
        loadingRapelSaldo: false
      }
    case 'ERROR_GET_PROFILE_WITHDRAW_USER':
      return {
        ...state,
        selectedUser: [],
        loadingRapelSaldo: false
      }
    case 'LOADING_AREA_WITHDRAW_USER':
      return {
        ...state,
        isInside: true
      }
    case 'GET_AREA_WITHDRAW_USER':
      return {
        ...state,
        isInside: action.isInside
      }
    case 'ERRAREA_WITHDRAW_USER':
      return {
        ...state,
        isInside: true
      }
    case 'LOADING_OTP_WITHDRAW_USER':
      return {
        ...state,
        otp: null,
        loadingOtp: true
      }
    case 'OTP_WITHDRAW_USER':
      return {
        ...state,
        otp: action.otp,
        loadingOtp: false
      }
    case 'ERROR_OTP_WITHDRAW_USER':
      return {
        ...state,
        otp: null,
        loadingOtp: false
      }
    case 'LOADING_WITHDRAW':
      return {
        ...state,
        loadingWithdraw: true
      }
    case 'STOP_LOADING_WITHDRAW':
      return {
        ...state,
        loadingWithdraw: false
      }
    default:
      return { ...state }
  }
}
export default withdrawUser
