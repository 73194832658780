// ** Initial State
const initialState = {
  loadingData: true,
  loadingDataLocation: true,
  data: [],
  dataExcel: [],
  dataLocation: [],
  month: null,
  isNext: false
}

const bonusReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING_GET_DATA_BONUS': 
      return {
        ...state,
        data: [],
        loadingData: true
      }
    case 'GET_DATA_BONUS': 
      return {
        ...state,
        data: action.data,
        loadingData: false
      }
    case 'ERROR_GET_DATA_BONUS': 
      return {
        ...state,
        data: [],
        loadingData: true
      }
    case 'DATA_LIST_CUTOFF_KOLEKTOR': 
      return {
        ...state,
        dataExcel: action.data
      }
    case 'HAPUS_DATA_LIST_CUTOFF_KOLEKTOR': 
      return {
        ...state,
        dataExcel: []
      }
      case 'LOADING_DATA_LOCATION_SHOWROOM_RAPEL': 
      return {
        ...state,
        dataLocation: [],
        loadingDataLocation: true
      }
    case 'GET_DATA_LOCATION_SHOWROOM_RAPEL': 
      return {
        ...state,
        dataLocation: action.dataLocation,
        loadingDataLocation: false
      }
    case 'ERROR_GET_DATA_LOCATION_SHOWROOM_RAPEL': 
      return {
        ...state,
        dataLocation: [],
        loadingDataLocation: true
      }
    case 'DATA_PERIODE_BONUS_KOLEKTOR': 
      return {
        ...state,
        month: action.data
      }
    case 'DATA_LIST_CUTOFF_KOLEKTOR_NEXT': 
      return {
        ...state,
        isNext: action.data
      }
    default:
      return { ...state }
  }
}
export default bonusReducer
