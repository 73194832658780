import mock from '../mock'
import { paginateArray } from '../utils'

const data = {
  mitras: [
    {
      id: 1,
      mitraName: 'Galen Slixby',
      mitraType: 'Yotz PVT LTD',
      kabupaten: 'editor',
      mitraname: 'gslixby0',
      currentPlan: 'enterprise',
      status: 'inactive',
      alamat: 'Ds. Lengkonglor, Kec. Ngluyu, Nganjuk, Jawa Timur',
      tanggalDaftar: '01 Januari 2021',
      avatar: ''
    },
    {
      id: 2,
      mitraName: 'Halsey Redmore',
      mitraType: 'Skinder PVT LTD',
      kabupaten: 'author',
      mitraname: 'hredmore1',
      currentPlan: 'team',
      status: 'active',
      alamat: 'Cundamani Residence, No. 12A, Berbah, kalasan, Sleman, Yogyakarta',
      tanggalDaftar: '02 Januari 2021',
      avatar: ''
    },
    {
      id: 3,
      mitraName: 'Marjory Sicely',
      mitraType: 'Oozz PVT LTD',
      kabupaten: 'maintainer',
      mitraname: 'msicely2',
      currentPlan: 'enterprise',
      status: 'active',
      alamat : 'Perum Muktimaguwo no. 1D, Sleman, Yogyakarta',
      tanggalDaftar: '03 Januari 2021',
      avatar: ''
    },
    {
      id: 4,
      mitraName: 'Cyrill Risby',
      mitraType: 'Oozz PVT LTD',
      kabupaten: 'maintainer',
      mitraname: 'crisby3',
      currentPlan: 'team',
      status: 'inactive',
      alamat: 'Jl. Kadirojo 1, RT.07/RW.02, Kadirojo I, Purwomartani, Kec. Kalasan, Kabupaten Sleman, Daerah Istimewa Yogyakarta',
      tanggalDaftar: '04 Januari 2021',
      avatar: ''
    },
    {
      id: 5,
      mitraName: 'Maggy Hurran',
      mitraType: 'Aimbo PVT LTD',
      kabupaten: 'subscriber',
      mitraname: 'mhurran4',
      currentPlan: 'enterprise',
      status: 'active',
      alamat: 'Jl. Candi Sambisari No.88, Purwomartani, Yogyakarta, Kabupaten Sleman, Juwangen, Purwomartani, Kalasan, Sleman Regency, Special Region of Yogyakarta 55571',
      tanggalDaftar: '05 Januari 2021',
      avatar: ''
    },
    {
      id: 6,
      mitraName: 'Silvain Halstead',
      mitraType: 'Jaxbean PVT LTD',
      kabupaten: 'author',
      mitraname: 'shalstead5',
      currentPlan: 'mitraType',
      status: 'active',
      alamat : 'Jl. Padjajaran Jl. Ring Road Utara No.160, Krodan, Maguwoharjo, Kec. Depok, Kabupaten Sleman, Daerah Istimewa Yogyakarta 55282',
      tanggalDaftar: '06 Januari 2021',
      avatar: ''
    },
    {
      id: 7,
      mitraName: 'Breena Gallemore',
      mitraType: 'Jazzy PVT LTD',
      kabupaten: 'subscriber',
      mitraname: 'bgallemore6',
      currentPlan: 'mitraType',
      status: 'active',
      alamat: 'Sefan, Maguwoharjo, Depok Sub-District, Sleman Regency, Special Region of Yogyakarta',
      tanggalDaftar: '07 Januari 2021',
      avatar: ''
    },
    {
      id: 8,
      mitraName: 'Kathryne Liger',
      mitraType: 'Pixoboo PVT LTD',
      kabupaten: 'author',
      mitraname: 'kliger7',
      currentPlan: 'enterprise',
      status: 'active',
      alamat: 'Jl. Ring Road Utara Meguwo, Maguwoharjo, Kec. Depok, Kabupaten Sleman, Daerah Istimewa Yogyakarta',
      tanggalDaftar: '08 Januari 2021',
      avatar: ''
    },
    {
      id: 9,
      mitraName: 'Franz Scotfurth',
      mitraType: 'Tekfly PVT LTD',
      kabupaten: 'subscriber',
      mitraname: 'fscotfurth8',
      currentPlan: 'team',
      status: 'active',
      alamat: 'Jl. Ring Road Utara, Meguwo, Maguwoharjo, Kec. Depok, Kabupaten Sleman, Daerah Istimewa Yogyakarta',
      tanggalDaftar: '09 Januari 2021',
      avatar: ''
    },
    {
      id: 10,
      mitraName: 'Jillene Bellany',
      mitraType: 'Gigashots PVT LTD',
      kabupaten: 'maintainer',
      mitraname: 'jbellany9',
      currentPlan: 'mitraType',
      status: 'inactive',
      alamat: 'Jl. Laksda Adisucipto KM.8, Janti, Caturtunggal, Kec. Depok, Kabupaten Sleman, Daerah Istimewa Yogyakarta',
      tanggalDaftar: '10 Januari 2021',
      avatar: ''
    },
    {
      id: 11,
      mitraName: 'Jonah Wharlton',
      mitraType: 'Eare PVT LTD',
      kabupaten: 'subscriber',
      mitraname: 'jwharltona',
      currentPlan: 'team',
      status: 'inactive',
      alamat: 'Jl. Kadirojo 1, RT.07/RW.02, Kadirojo I, Purwomartani, Kec. Kalasan, Kabupaten Sleman, Daerah Istimewa Yogyakarta',
      tanggalDaftar: '11 Januari 2021',
      avatar: ''
    },
    {
      id: 12,
      mitraName: 'Seth Hallam',
      mitraType: 'Yakitri PVT LTD',
      kabupaten: 'subscriber',
      mitraname: 'shallamb',
      currentPlan: 'team',
      status: 'active',
      alamat : 'Jl. Padjajaran Jl. Ring Road Utara No.160, Krodan, Maguwoharjo, Kec. Depok, Kabupaten Sleman, Daerah Istimewa Yogyakarta',
      tanggalDaftar: '12 Januari 2021',
      avatar: ''
    },
    {
      id: 13,
      mitraName: 'Yoko Pottie',
      mitraType: 'Leenti PVT LTD',
      kabupaten: 'subscriber',
      mitraname: 'ypottiec',
      currentPlan: 'basic',
      status: 'inactive',
      alamat: 'Sefan, Maguwoharjo, Depok Sub-District, Sleman Regency, Special Region of Yogyakarta',
      tanggalDaftar: '13 Januari 2021',
      avatar: ''
    },
    {
      id: 14,
      mitraName: 'Maximilianus Krause',
      mitraType: 'Digitube PVT LTD',
      kabupaten: 'author',
      mitraname: 'mkraused',
      currentPlan: 'team',
      status: 'active',
      alamat: 'Jl. Ring Road Utara, Meguwo, Maguwoharjo, Kec. Depok, Kabupaten Sleman, Daerah Istimewa Yogyakarta',
      tanggalDaftar: '14 Januari 2021',
      avatar: ''
    },
    {
      id: 15,
      mitraName: 'Zsazsa McCleverty',
      mitraType: 'Kaymbo PVT LTD',
      kabupaten: 'maintainer',
      mitraname: 'zmcclevertye',
      currentPlan: 'enterprise',
      status: 'active',
      alamat: 'Jl. Kadirojo 1, RT.07/RW.02, Kadirojo I, Purwomartani, Kec. Kalasan, Kabupaten Sleman, Daerah Istimewa Yogyakarta',
      tanggalDaftar: '15 Januari 2021',
      avatar: ''
    },
    {
      id: 16,
      mitraName: 'Bentlee Emblin',
      mitraType: 'Yambee PVT LTD',
      kabupaten: 'author',
      mitraname: 'bemblinf',
      currentPlan: 'mitraType',
      status: 'active',
      alamat : 'Jl. Padjajaran Jl. Ring Road Utara No.160, Krodan, Maguwoharjo, Kec. Depok, Kabupaten Sleman, Daerah Istimewa Yogyakarta',
      tanggalDaftar: '16 Januari 2021',
      avatar: ''
    },
    {
      id: 17,
      mitraName: 'Brockie Myles',
      mitraType: 'Wikivu PVT LTD',
      kabupaten: 'maintainer',
      mitraname: 'bmylesg',
      currentPlan: 'basic',
      status: 'active',
      alamat: 'Sefan, Maguwoharjo, Depok Sub-District, Sleman Regency, Special Region of Yogyakarta',
      tanggalDaftar: '17 Januari 2021',
      avatar: ''
    },
    {
      id: 18,
      mitraName: 'Bertha Biner',
      mitraType: 'Twinte PVT LTD',
      kabupaten: 'editor',
      mitraname: 'bbinerh',
      currentPlan: 'team',
      status: 'active',
      alamat: 'Jl. Ring Road Utara, Meguwo, Maguwoharjo, Kec. Depok, Kabupaten Sleman, Daerah Istimewa Yogyakarta',
      tanggalDaftar: '18 Januari 2021',
      avatar: ''
    },
    {
      id: 19,
      mitraName: 'Travus Bruntjen',
      mitraType: 'Cogidoo PVT LTD',
      kabupaten: 'admin',
      mitraname: 'tbruntjeni',
      currentPlan: 'enterprise',
      status: 'active',
      alamat: 'Jl. Kadirojo 1, RT.07/RW.02, Kadirojo I, Purwomartani, Kec. Kalasan, Kabupaten Sleman, Daerah Istimewa Yogyakarta',
      tanggalDaftar: '19 Januari 2021',
      avatar: ''
    },
    {
      id: 20,
      mitraName: 'Wesley Burland',
      mitraType: 'Bubblemix PVT LTD',
      kabupaten: 'editor',
      mitraname: 'wburlandj',
      currentPlan: 'team',
      status: 'inactive',
      alamat : 'Jl. Padjajaran Jl. Ring Road Utara No.160, Krodan, Maguwoharjo, Kec. Depok, Kabupaten Sleman, Daerah Istimewa Yogyakarta',
      tanggalDaftar: '20 Januari 2021',
      avatar: ''
    },
    {
      id: 21,
      mitraName: 'Selina Kyle',
      mitraType: 'Wayne Enterprises',
      kabupaten: 'admin',
      mitraname: 'catwomen1940',
      currentPlan: 'team',
      status: 'active',
      alamat: 'Jl. Ring Road Utara, Meguwo, Maguwoharjo, Kec. Depok, Kabupaten Sleman, Daerah Istimewa Yogyakarta',
      tanggalDaftar: '21 Januari 2021',
      avatar: ''
    },
    {
      id: 22,
      mitraName: 'Jameson Lyster',
      mitraType: 'Quaxo PVT LTD',
      kabupaten: 'editor',
      mitraname: 'jlysterl',
      currentPlan: 'mitraType',
      status: 'inactive',
      alamat: 'Jl. Kadirojo 1, RT.07/RW.02, Kadirojo I, Purwomartani, Kec. Kalasan, Kabupaten Sleman, Daerah Istimewa Yogyakarta',
      tanggalDaftar: '22 Januari 2021',
      avatar: ''
    },
    {
      id: 23,
      mitraName: 'Kare Skitterel',
      mitraType: 'Ainyx PVT LTD',
      kabupaten: 'maintainer',
      mitraname: 'kskitterelm',
      currentPlan: 'basic',
      status: 'active',
      alamat : 'Jl. Padjajaran Jl. Ring Road Utara No.160, Krodan, Maguwoharjo, Kec. Depok, Kabupaten Sleman, Daerah Istimewa Yogyakarta',
      tanggalDaftar: '23 Januari 2021',
      avatar: ''
    },
    {
      id: 24,
      mitraName: 'Cleavland Hatherleigh',
      mitraType: 'Flipopia PVT LTD',
      kabupaten: 'admin',
      mitraname: 'chatherleighn',
      currentPlan: 'team',
      status: 'active',
      alamat: 'Sefan, Maguwoharjo, Depok Sub-District, Sleman Regency, Special Region of Yogyakarta',
      tanggalDaftar: '24 Januari 2021',
      avatar: ''
    },
    {
      id: 25,
      mitraName: 'Adeline Micco',
      mitraType: 'Topicware PVT LTD',
      kabupaten: 'admin',
      mitraname: 'amiccoo',
      currentPlan: 'enterprise',
      status: 'active',
      alamat: 'Jl. Kadirojo 1, RT.07/RW.02, Kadirojo I, Purwomartani, Kec. Kalasan, Kabupaten Sleman, Daerah Istimewa Yogyakarta',
      tanggalDaftar: '25 Januari 2021',
      avatar: ''
    },
    {
      id: 26,
      mitraName: 'Hugh Hasson',
      mitraType: 'Skinix PVT LTD',
      kabupaten: 'admin',
      mitraname: 'hhassonp',
      currentPlan: 'basic',
      status: 'inactive',
      alamat: 'Jl. Ring Road Utara, Meguwo, Maguwoharjo, Kec. Depok, Kabupaten Sleman, Daerah Istimewa Yogyakarta',
      tanggalDaftar: '26 Januari 2021',
      avatar: ''
    },
    {
      id: 27,
      mitraName: 'Germain Jacombs',
      mitraType: 'Youopia PVT LTD',
      kabupaten: 'editor',
      mitraname: 'gjacombsq',
      currentPlan: 'enterprise',
      status: 'active',
      alamat : 'Jl. Padjajaran Jl. Ring Road Utara No.160, Krodan, Maguwoharjo, Kec. Depok, Kabupaten Sleman, Daerah Istimewa Yogyakarta',
      tanggalDaftar: '27 Januari 2021',
      avatar: ''
    },
    {
      id: 28,
      mitraName: 'Bree Kilday',
      mitraType: 'Jetpulse PVT LTD',
      kabupaten: 'maintainer',
      mitraname: 'bkildayr',
      currentPlan: 'team',
      status: 'active',
      alamat: 'Jl. Ring Road Utara, Meguwo, Maguwoharjo, Kec. Depok, Kabupaten Sleman, Daerah Istimewa Yogyakarta',
      tanggalDaftar: '30 Januari 2021',
      avatar: ''
    },
    {
      id: 29,
      mitraName: 'Candice Pinyon',
      mitraType: 'Kare PVT LTD',
      kabupaten: 'maintainer',
      mitraname: 'cpinyons',
      currentPlan: 'team',
      status: 'active',
      alamat: 'Jl. Kadirojo 1, RT.07/RW.02, Kadirojo I, Purwomartani, Kec. Kalasan, Kabupaten Sleman, Daerah Istimewa Yogyakarta',
      tanggalDaftar: '28 Januari 2021',
      avatar: ''
    },
    {
      id: 30,
      mitraName: 'Isabel Mallindine',
      mitraType: 'Voomm PVT LTD',
      kabupaten: 'subscriber',
      mitraname: 'imallindinet',
      currentPlan: 'team',
      status: 'active',
      alamat: 'Jl. Ring Road Utara, Meguwo, Maguwoharjo, Kec. Depok, Kabupaten Sleman, Daerah Istimewa Yogyakarta',
      tanggalDaftar: '29 Januari 2021',
      avatar: ''
    },
    {
      id: 31,
      mitraName: 'Gwendolyn Meineken',
      mitraType: 'Oyondu PVT LTD',
      kabupaten: 'admin',
      mitraname: 'gmeinekenu',
      currentPlan: 'basic',
      status: 'active',
      alamat : 'Jl. Padjajaran Jl. Ring Road Utara No.160, Krodan, Maguwoharjo, Kec. Depok, Kabupaten Sleman, Daerah Istimewa Yogyakarta',
      tanggalDaftar: '31 Januari 2021',
      avatar: ''
    },
    {
      id: 32,
      mitraName: 'Rafaellle Snowball',
      mitraType: 'Fivespan PVT LTD',
      kabupaten: 'editor',
      mitraname: 'rsnowballv',
      currentPlan: 'basic',
      status: 'active',
      alamat: 'Sefan, Maguwoharjo, Depok Sub-District, Sleman Regency, Special Region of Yogyakarta',
      tanggalDaftar: '01 Februari 2021',
      avatar: ''
    },
    {
      id: 33,
      mitraName: 'Rochette Emer',
      mitraType: 'Thoughtworks PVT LTD',
      kabupaten: 'admin',
      mitraname: 'remerw',
      currentPlan: 'basic',
      status: 'active',
      alamat: 'Jl. Ring Road Utara, Meguwo, Maguwoharjo, Kec. Depok, Kabupaten Sleman, Daerah Istimewa Yogyakarta',
      tanggalDaftar: '02 Februari 2021',
      avatar: ''
    },
    {
      id: 34,
      mitraName: 'Ophelie Fibbens',
      mitraType: 'Jaxbean PVT LTD',
      kabupaten: 'subscriber',
      mitraname: 'ofibbensx',
      currentPlan: 'mitraType',
      status: 'active',
      alamat: 'Jl. Kadirojo 1, RT.07/RW.02, Kadirojo I, Purwomartani, Kec. Kalasan, Kabupaten Sleman, Daerah Istimewa Yogyakarta',
      tanggalDaftar: '03 Februari 2021',
      avatar: ''
    },
    {
      id: 35,
      mitraName: 'Stephen MacGilfoyle',
      mitraType: 'Browseblab PVT LTD',
      kabupaten: 'maintainer',
      mitraname: 'smacgilfoyley',
      currentPlan: 'mitraType',
      status: 'active',
      alamat: 'Jl. Ring Road Utara, Meguwo, Maguwoharjo, Kec. Depok, Kabupaten Sleman, Daerah Istimewa Yogyakarta',
      tanggalDaftar: '04 Februari 2021',
      avatar: ''
    },
    {
      id: 36,
      mitraName: 'Bradan Rosebotham',
      mitraType: 'Agivu PVT LTD',
      kabupaten: 'subscriber',
      mitraname: 'brosebothamz',
      currentPlan: 'team',
      status: 'inactive',
      alamat : 'Jl. Padjajaran Jl. Ring Road Utara No.160, Krodan, Maguwoharjo, Kec. Depok, Kabupaten Sleman, Daerah Istimewa Yogyakarta',
      tanggalDaftar: '05 Februari 2021',
      avatar: ''
    },
    {
      id: 37,
      mitraName: 'Skip Hebblethwaite',
      mitraType: 'Katz PVT LTD',
      kabupaten: 'admin',
      mitraname: 'shebblethwaite10',
      currentPlan: 'mitraType',
      status: 'inactive',
      alamat: 'Jl. Ring Road Utara, Meguwo, Maguwoharjo, Kec. Depok, Kabupaten Sleman, Daerah Istimewa Yogyakarta',
      tanggalDaftar: '06 Februari 2021',
      avatar: ''
    },
    {
      id: 38,
      mitraName: 'Moritz Piccard',
      mitraType: 'Twitternation PVT LTD',
      kabupaten: 'maintainer',
      mitraname: 'mpiccard11',
      currentPlan: 'enterprise',
      status: 'inactive',
      alamat: 'Jl. Kadirojo 1, RT.07/RW.02, Kadirojo I, Purwomartani, Kec. Kalasan, Kabupaten Sleman, Daerah Istimewa Yogyakarta',
      tanggalDaftar: '07 Februari 2021',
      avatar: ''
    },
    {
      id: 39,
      mitraName: 'Tyne Widmore',
      mitraType: 'Yombu PVT LTD',
      kabupaten: 'subscriber',
      mitraname: 'twidmore12',
      currentPlan: 'team',
      status: 'active',
      alamat : 'Jl. Padjajaran Jl. Ring Road Utara No.160, Krodan, Maguwoharjo, Kec. Depok, Kabupaten Sleman, Daerah Istimewa Yogyakarta',
      tanggalDaftar: '08 Februari 2021',
      avatar: ''
    },
    {
      id: 40,
      mitraName: 'Florenza Desporte',
      mitraType: 'Kamba PVT LTD',
      kabupaten: 'author',
      mitraname: 'fdesporte13',
      currentPlan: 'mitraType',
      status: 'active',
      alamat: 'Jl. Kadirojo 1, RT.07/RW.02, Kadirojo I, Purwomartani, Kec. Kalasan, Kabupaten Sleman, Daerah Istimewa Yogyakarta',
      tanggalDaftar: '09 Februari 2021',
      avatar: ''
    },
    {
      id: 41,
      mitraName: 'Edwina Baldetti',
      mitraType: 'Dazzlesphere PVT LTD',
      kabupaten: 'maintainer',
      mitraname: 'ebaldetti14',
      currentPlan: 'team',
      status: 'active',
      alamat: 'Jl. Kadirojo 1, RT.07/RW.02, Kadirojo I, Purwomartani, Kec. Kalasan, Kabupaten Sleman, Daerah Istimewa Yogyakarta',
      tanggalDaftar: '10 Februari 2021',
      avatar: ''
    },
    {
      id: 42,
      mitraName: 'Benedetto Rossiter',
      mitraType: 'Mybuzz PVT LTD',
      kabupaten: 'editor',
      mitraname: 'brossiter15',
      currentPlan: 'team',
      status: 'inactive',
      alamat: 'Jl. Ring Road Utara, Meguwo, Maguwoharjo, Kec. Depok, Kabupaten Sleman, Daerah Istimewa Yogyakarta',
      tanggalDaftar: '11 Februari 2021',
      avatar: ''
    },
    {
      id: 43,
      mitraName: 'Micaela McNirlan',
      mitraType: 'Tambee PVT LTD',
      kabupaten: 'admin',
      mitraname: 'mmcnirlan16',
      currentPlan: 'basic',
      status: 'inactive',
      alamat : 'Jl. Padjajaran Jl. Ring Road Utara No.160, Krodan, Maguwoharjo, Kec. Depok, Kabupaten Sleman, Daerah Istimewa Yogyakarta',
      tanggalDaftar: '12 Februari 2021',
      avatar: ''
    },
    {
      id: 44,
      mitraName: 'Vladamir Koschek',
      mitraType: 'Centimia PVT LTD',
      kabupaten: 'author',
      mitraname: 'vkoschek17',
      currentPlan: 'team',
      status: 'active',
      alamat: 'Jl. Ring Road Utara, Meguwo, Maguwoharjo, Kec. Depok, Kabupaten Sleman, Daerah Istimewa Yogyakarta',
      tanggalDaftar: '13 Februari 2021',
      avatar: ''
    },
    {
      id: 45,
      mitraName: 'Corrie Perot',
      mitraType: 'Flipopia PVT LTD',
      kabupaten: 'subscriber',
      mitraname: 'cperot18',
      currentPlan: 'team',
      status: 'active',
      alamat: 'Jl. Kadirojo 1, RT.07/RW.02, Kadirojo I, Purwomartani, Kec. Kalasan, Kabupaten Sleman, Daerah Istimewa Yogyakarta',
      tanggalDaftar: '14 Februari 2021',
      avatar: ''
    },
    {
      id: 46,
      mitraName: 'Saunder Offner',
      mitraType: 'Skalith PVT LTD',
      kabupaten: 'maintainer',
      mitraname: 'soffner19',
      currentPlan: 'enterprise',
      status: 'active',
      alamat : 'Jl. Padjajaran Jl. Ring Road Utara No.160, Krodan, Maguwoharjo, Kec. Depok, Kabupaten Sleman, Daerah Istimewa Yogyakarta',
      tanggalDaftar: '15 Februari 2021',
      avatar: ''
    },
    {
      id: 47,
      mitraName: 'Karena Courtliff',
      mitraType: 'Feedfire PVT LTD',
      kabupaten: 'admin',
      mitraname: 'kcourtliff1a',
      currentPlan: 'basic',
      status: 'active',
      alamat: 'Jl. Ring Road Utara, Meguwo, Maguwoharjo, Kec. Depok, Kabupaten Sleman, Daerah Istimewa Yogyakarta',
      tanggalDaftar: '16 Februari 2021',
      avatar: ''
    },
    {
      id: 48,
      mitraName: 'Onfre Wind',
      mitraType: 'Thoughtmix PVT LTD',
      kabupaten: 'admin',
      mitraname: 'owind1b',
      currentPlan: 'basic',
      status: 'active',
      alamat: 'Jl. Kadirojo 1, RT.07/RW.02, Kadirojo I, Purwomartani, Kec. Kalasan, Kabupaten Sleman, Daerah Istimewa Yogyakarta',
      tanggalDaftar: '17 Februari 2021',
      avatar: ''
    },
    {
      id: 49,
      mitraName: 'Paulie Durber',
      mitraType: 'Babbleblab PVT LTD',
      kabupaten: 'subscriber',
      mitraname: 'pdurber1c',
      currentPlan: 'team',
      status: 'inactive',
      alamat: 'Jl. Ring Road Utara, Meguwo, Maguwoharjo, Kec. Depok, Kabupaten Sleman, Daerah Istimewa Yogyakarta',
      tanggalDaftar: '18 Februari 2021',
      avatar: ''
    },
    {
      id: 50,
      mitraName: 'Beverlie Krabbe',
      mitraType: 'Kaymbo PVT LTD',
      kabupaten: 'editor',
      mitraname: 'bkrabbe1d',
      currentPlan: 'mitraType',
      status: 'active',
      alamat: 'Sefan, Maguwoharjo, Depok Sub-District, Sleman Regency, Special Region of Yogyakarta',
      tanggalDaftar: '19 Februari 2021',
      avatar: ''
    }
  ]
}

// GET ALL DATA
mock.onGet('/api/mitras/list/all-data').reply(200, data.mitras)

// POST: Add new mitra
mock.onPost('/apps/mitras/add-mitra').reply(config => {
  // Get event from post data
  const mitra = JSON.parse(config.data)

  const { length } = data.mitras
  let lastIndex = 0
  if (length) {
    lastIndex = data.mitras[length - 1].id
  }
  mitra.id = lastIndex + 1

  data.mitras.unshift(mitra)

  return [201, { mitra }]
})

// GET Updated DATA
mock.onGet('/api/mitras/list/data').reply(config => {
  const { q = '', perPage = 10, page = 1, kabupaten = null, currentPlan = null, status = null } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()
  const filteredData = data.mitras.filter(
    mitra =>
      (mitra.mitraname.toLowerCase().includes(queryLowered) || mitra.mitraName.toLowerCase().includes(queryLowered)) &&
      mitra.kabupaten === (kabupaten || mitra.kabupaten) &&
      mitra.currentPlan === (currentPlan || mitra.currentPlan) &&
      mitra.status === (status || mitra.status)
  )
  /* eslint-enable  */

  return [
    200,
    {
      mitras: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

// GET mitra
mock.onGet('/api/mitras/mitra').reply(config => {
  const { id } = config
  const mitra = data.mitras.find(i => i.id === id)
  return [200, { mitra }]
})

// DELETE: Deletes mitra
mock.onDelete('/apps/mitras/delete').reply(config => {
  // Get mitra id from URL
  let mitraId = config.id

  // Convert Id to number
  mitraId = Number(mitraId)

  const mitraIndex = data.mitras.findIndex(t => t.id === mitraId)
  data.mitras.splice(mitraIndex, 1)

  return [200]
})
