// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import menuReducer from './menu'
import users from '@src/views/pages/user/store/reducer'
import userUmum from '@src/views/administrasi/user-umum/store/reducer'
import allUserRapel from '@src/views/administrasi/all-user/store/reducer'
import kolektor from '@src/views/administrasi/kolektor/store/reducer'
import userRapel from '@src/views/administrasi/user-rapel/store/reducer'
import postingactive from '@src/views/buser/posting-list-buser/store/reducer'
import reportRapelSaldoBalance from '@src/views/report/keuangan/balance/store/reducer'
import reportTransaksiRapelApp from '@src/views/report/keuangan/transaksi-rapel-app/store/reducer'
import reportTransaksiRapelSaldo from '@src/views/report/keuangan/transaksi-rapel-saldo/store/reducer'
import errorLog from '@src/views/log/store/reducer'
import berita from '@src/views/marketing/berita/store/reducer'
import pushNotif from '@src/views/marketing/push-notif/store/reducer'
import expand from '@src/views/report/map/store/reducer'
import reportTransaksiRapelAppMitraBisnis from '@src/views/report/mitra-bisnis/store/reducer'
import adminDashboard from '@src/views/dashboard/store/reducer'
import rapelAdminDashboard from '@src/views/dashboard-admin/store/reducer'
import pembelianReducer from '@src/views/showroom/pembelian/store/reducer'
import withdrawApproval from '@src/views/rapelsaldo/withdraw-approval/store/reducer'
import companyList from '@src/views/administrasi/company/store/reducer'
import pembelian from '@src/views/apps/pembelian/store/reducer'
import locationPriceReducer from '@src/views/administrasi/location-price/location/store/reducer'
import companyPriceReducer from '@src/views/administrasi/location-price/company/store/reducer'
import defaultPriceReducer from '@src/views/administrasi/location-price/default/store/reducer'
import priceReducer from '@src/views/administrasi/location-price/price/store/reducer'
import calenderReducer from '@src/views/administrasi/schedule/detail/store/reducer'
import scheduleReducer from '@src/views/administrasi/schedule/store/reducer'
import reportPurchase from '@src/views/report/purchase/store/reducer'
import profileUserRapel from '@src/views/profile/store/reducer'
import withdrawUser from '@src/views/rapelsaldo/withdraw/store/reducer'
import bonusReducer from '@src/views/report/kolektor/bonus/store/reducer'

const rootReducer = combineReducers({
  auth,
  menuReducer,
  users,
  userUmum,
  allUserRapel,
  kolektor,
  userRapel,
  postingactive,
  reportRapelSaldoBalance,
  reportTransaksiRapelApp,
  reportTransaksiRapelSaldo,
  errorLog,
  pushNotif,
  expand,
  reportTransaksiRapelAppMitraBisnis,
  adminDashboard,
  rapelAdminDashboard,
  pembelianReducer,
  withdrawApproval,
  companyList,
  locationPriceReducer,
  companyPriceReducer,
  defaultPriceReducer,
  priceReducer,
  calenderReducer,
  scheduleReducer,
  reportPurchase,
  navbar,
  layout,
  pembelian,
  berita,
  profileUserRapel,
  withdrawUser,
  bonusReducer
})

export default rootReducer
