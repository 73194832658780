// ** Initial State
const initialState = {
  allData: [],
  loadingData: true,
  loadingAddNews: false,
  loadingDetail: true,
  data: [],
  params: {},
  search: null,
  page: 0,
  //currentPage: 1,
  totalPages: 1,
  id: null,
  selectedNews: {}
}

const berita = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_NEWS':
      return { ...state, statusNewsData: action.statusNewsData }
    case 'LOADING_DATA_NEWS':
      return {
        ...state,
        data: [],
        loadingData: true
      }
    case 'LOADING_ADD_NEWS':
      return {
        ...state,
        loadingAddNews: true
      }
    case 'LOADING_DETAIL_NEWS':
      return {
        ...state,
        selectedNews: {},
        loadingDetail: true
      }
    case 'POST_URL_NEWS':
      return {
        ...state,
        search: action.search,
        page: action.page
      }
    case 'GET_NEWS_DETAIL':
      return {
        ...state,
        id: action.iduser,  
        selectedNews: action.selectedNews,
        loadingDetail: false,
        statusNewsData: action.statusNewsData 
      }
    case 'GET_SEARCH_NEWS':
      return {
        ...state,
        data: action.data,
        loadingData: false,
        loadingAddNews: false,
        loadingDetail: true,
        params: action.params,
        search: null,
        page: null,
        //currentPage: action.currentPage,
        totalPages: action.totalPages,
        id: null,
        selectedNews: {}
      }
    case 'ADD_NEWS':
      return { ...state }
    case 'EDIT_NEWS':
      return { 
        ...state, 
        selectedNews: {},
        statusNewsData: action.statusNewsData }
    case 'ERROR_GET_SEARCH_NEWS':
      return { 
        ...state, 
        data: [] }
    case 'ERROR_GET_NEWS':
      return { 
        ...state, 
        statusNewsData: action.statusNewsData }
    case 'ERROR_EDIT_NEWS':
      return { 
        ...state, 
        statusNewsData: action.statusNewsData }
    default:
      return { ...state }
  }
}
export default berita
