// ** Initial State
const initialState = {
  data: [],
  loadingData: true,
  dataKategori: [],
  listCabangPerusahaan: []
}

const reportPurchase = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING_REPORT_PURCHASE':
      return {
        ...state, 
        data: [],
        loadingData: true 
      }
    case 'GET_REPORT_PURCHASE':
      return {
        ...state, 
        data: action.data,
        loadingData: false 
      }
    case 'ERROR_GET_REPORT_PURCHASE':
      return {
        ...state, 
        data: [],
        loadingData: false 
      }
    case 'GET_DATA_KATEGORI_LIST_PURCHASE_REPORT':
      return {
        ...state, 
        dataKategori: action.dataKategori
      }
    case 'GET_CABANG_PERUSAHAAN_PURCHASE_REPORT':
      return {
        ...state, 
        listCabangPerusahaan: action.listCabangPerusahaan
      }
    default:
      return { ...state }
  }
}
export default reportPurchase
