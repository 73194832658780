// ** Initial State
const initialState = {
  loadingData: true,
  data: [],
  dataBranch: [],
  listTreeDataChild: [],
  loadingDataBranch: true,
  params: {},
  search: null,
  status: null,
  page: 0,
  id: null,
  selectedKolektor: {},
  loadingDetailKolektor: true
}

const companyList = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING_DATA_COMPANY_LIST_ADMIN':
      return {
        ...state,
        data: [],
        loadingData: true
      }
    case 'GET_DATA_COMPANY_LIST_ADMIN':
      return {
        ...state,
        data: action.data,
        loadingData: false,
        params: action.params
      }
    case 'ERROR_GET_DATA_COMPANY_LIST_ADMIN':
      return {
        ...state,
        data: [],
        loadingData: false
      }
      case 'LOADING_DATA_BRANCH_COMPANY_LIST_ADMIN':
      return {
        ...state,
        listTreeDataChild: [],
        loadingDataBranch: true
      }
    case 'GET_DATA_BRANCH_COMPANY_LIST_ADMIN':
      const idCompany = action.idCompany
      const tmpDataListChild = action.dataBranch.filter(f => (f.parentId === Number(idCompany) || f.companyId === Number(idCompany)))
      const tmpListTreeDataChild = []
      if (tmpDataListChild.length > 0) {
        for (let a = 0; a <= tmpListTreeDataChild.length; a++) {
          for (let b = 0; b < action.dataBranch.length; b++) {
            const tmpId = tmpListTreeDataChild[a] !== undefined ? tmpListTreeDataChild[a].companyId : Number(idCompany)
            const filter = action.dataBranch.filter(f => (f.parentId === (tmpId || Number(idCompany)) || f.companyId === (tmpId || Number(idCompany))))
            filter.map(item => {
              if (tmpListTreeDataChild.find(s => s.companyId === item.companyId) === undefined) {
                tmpListTreeDataChild.push({
                  companyId: item.companyId,
                  companyName: item.companyName,
                  companyAddress: item.companyAddress,
                  npwp: item.npwp,
                  status: item.status,
                  parentId: item.parentId === null ? 0 : item.parentId,
                  createdByUserId: item.createdByUserId,
                  createdByDateTime: item.createdByDateTime,
                  modifiedByUserId: item.modifiedByUserId,
                  modifiedByDateTime: item.modifiedByDateTime
                })
              }
            })
          }
        }
      }
      return {
        ...state,
        listTreeDataChild: tmpListTreeDataChild,
        loadingDataBranch: false
      }
    case 'ERROR_GET_DATA_BRANCH_COMPANY_LIST_ADMIN':
      return {
        ...state,
        listTreeDataChild: [],
        loadingDataBranch: false
      }
    case 'POST_URL_BRANCH_COMPANY_LIST_ADMIN':
      return {
        ...state,
        search: action.search,
        status: action.status,
        page: action.page
      }
    case 'HAPUS_POST_URL_BRANCH_COMPANY_LIST_ADMIN':
      return {
        ...state,
        search: null,
        status: null,
        page: 0
      }
    default:
      return { ...state }
  }
}
export default companyList
