// ** Initial State
const initialState = {
  loadingData: true,
  data: [],
  //currentPage: 1,
  totalPages: 1,
  params: {},
  search: null,
  status: null,
  page: 0,
  selectedData: {},
  companyList: [],
  loadingDetailSchedule: true
}

const scheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING_DATA_SCHEDULE':
      return {
        ...state,
        data: [],
        loadingData: true
      }
    case 'GET_DATA_SCHEDULE':
      return {
        ...state,
        data: action.data,
        loadingData: false,
        params: action.params,
        search: null,
        status: null,
        page: null,
        totalPages: action.totalPages,
        selectedData: {}
      }
    case 'ERROR_GET_DATA_SCHEDULE':
      return { 
        ...state, 
        loadingData: false }
    case 'POST_URL_SCHEDULE':
      return {
        ...state,
        search: action.search,
        status: action.status,
        page: action.page
      }
    case 'LOADING_DETAIL_SCHEDULE_RAPEL':
      return {
        ...state,
        loadingDetailSchedule: true
      }
    case 'SELECTED_SCHEDULE':
      return {
        ...state,
        selectedData: action.selectedData
      }
    case 'ERROR_DETAIL_SCHEDULE_RAPEL':
      return {
        ...state,
        loadingDetailSchedule: false
      }
    case 'GET_DATA_COMPANY_LIST_ADMIN_SCHEDULE':
      return {
        ...state,
        companyList: action.dataListCompany
      }
    default:
      return { ...state }
  }
}
export default scheduleReducer
