// ** Initial State
const initialState = {
  alldata: [],
  loadingData: true,
  data: [],
  dataKolektor: [],
  lat: -1.9870862,
  lng: 117.2226137,
  zoom: 5,
  idSelectPosting: null,
  idSelectKolektor: null,
  currentPage: 1,
  totalPages: 1,
  params: {},
  id: null,
  selectedPostingBuser: null,
  loadingDetailPostingBuser: false
}

const postingactive = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA_POSTING_BUSER':
      return { ...state, alldata: action.data }
    case 'UPDATE_MAP':
      return { 
        ...state, 
        lat: action.lat,
        lng: action.lng,
        zoom: action.zoom,
        idSelectPosting: action.idSelectPosting,
        idSelectKolektor: action.idSelectKolektor}
    case 'LOADING_DETAIL_POSTING_BUSER': 
      return {
        ...state,
        selectedPostingBuser: null,
        id: null,
        loadingDetailPostingBuser: true
      }
    case 'LOADING_DATA_POSTING_BUSER':
      return {
        ...state,
        data: [],
        loadingData: true
      }
    case 'GET_DETAIL_POSTING_BUSER':
      return {
        ...state,
        id: action.idposting,  
        selectedPostingBuser: action.selectedPostingBuser,
        loadingDetailPostingBuser: false 
      }
    case 'GET_DATA_POSTING_BUSER':
      return {
        ...state,
        data: action.data,
        loadingData: false,
        params: action.params,
        currentPage: action.currentPage,
        totalPages: action.totalPages
      }
    case 'LOADING_GET_DATA_KOLEKTOR_RAPEL_POSTING_BUSER':
      return {
        ...state,
        dataKolektor: []
      }
    case 'GET_DATA_KOLEKTOR_RAPEL_POSTING_BUSER':
      return {
        ...state,
        dataKolektor: action.dataKolektor
      }
    case 'ERROR_GET_All_DATA_POSTING_BUSER':
      return {
        ...state,
        alldata: [],
        loadingData: false
      }
    case 'ERROR_GET_DATA_POSTING_BUSER':
      return { 
        ...state, 
        data: [],
        loadingData: false }
    case 'ERROR_GET_DETAIL_POSTING_BUSER':
      return { 
        ...state,
        selectedPostingBuser: null,
        loadingDetailPostingBuser: false }
    case 'ERROR_UBAH_JADWAL_POSTING_BUSER':
      return { 
        ...state,
        loadingDetailPostingBuser: false }
    case 'ERROR_APPROVE_POSTING_BUSER':
      return { 
        ...state,
        loadingDetailPostingBuser: false }
    case 'ERROR_UPDATE_KOLEKTOR_POSTING_BUSER':
      return { 
        ...state,
        loadingDetailPostingBuser: false }
    default:
      return { ...state }
  }
}
export default postingactive
