import mock from './mock'
import './tables/datatables'
import './autoComplete/autoComplete'
import './navbar/navbarSearch'
import './apps/userList'
import './apps/mitra'
import './apps/pembelian'
import './apps/kategori'
import './pages/account-settings'
import './pages/profile'
import './pages/faq'
import './pages/knowledge-base'
import './pages/pricing-data'
import './cards/card-analytics'
import './cards/card-statistics'
import './kabupaten/kabupaten'
import './jwt'
import './apps/calendar'

mock.onAny().passThrough()
