// ** Initial State
const initialState = {
  loadingData: true,
  loadingDataLocation: true,
  data: [],
  dataLocation: [],
  dataAllUser: [],
  dataNews: [],
  dataReward: [],
  currentPage: 1,
  totalPages: 1,
  params: {},
  currentPageUser: 1,
  totalPagesUser: 1,
  paramsUser: {},
  id: null,
  selectedPushNotif: {},
  loadingDetailPushNotif: true,
  loadingDataAllUser: true,
  loadingEffectedUser: true,
  totalAffectedUser: 0,
  loadingSendNotif: false
}

const pushNotif = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING_DATA_LOCATION_PUSH_NOTIF':
      return {
        ...state,
        dataLocation: [],
        loadingDataLocation: true
      }
    case 'GET_DATA_LOCATION_PUSH_NOTIF':
      return {
        ...state,
        dataLocation: action.dataLocation,
        loadingDataLocation: false
      }
    case 'ERROR_GET_DATA_LOCATION_PUSH_NOTIF':
      return {
        ...state, 
        dataLocation: [],
        loadingDataLocation: false
      }
    case 'LOADING_SEND_NOTIF': 
      return {
        ...state,
        loadingSendNotif: true
      }
    case 'LOADING_DETAIL_PUSH_NOTIF': 
      return {
        ...state,
        loadingDetailPushNotif: true,
        selectedPushNotif: {}
      }
    case 'LOADING_EFFECTED_USER_PUSH_NOTIF': 
      return {
        ...state,
        loadingEffectedUser: true,
        totalAffectedUser: 0
      }
    case 'LOADING_DATA_PUSH_NOTIF':
      return {
        ...state,
        data: [],
        loadingData: true
      }
    case 'LOADING_DATA_ALL_USER_PUSH_NOTIF':
      return {
        ...state,
        loadingDataAllUser: true,
        dataAllUser: []
      }
    case 'GET_DETAIL_PUSH_NOTIF':
      return {
        ...state,
        id: action.pushNotifId,  
        selectedPushNotif: action.selectedPushNotif,
        loadingDetailPushNotif: false 
      }
    case 'GET_DATA_PUSH_NOTIF':
      return {
        ...state,
        data: action.data,
        loadingData: false,
        params: action.params,
        id: null,
        currentPage: action.currentPage,
        totalPages: action.totalPages,
        selectedPushNotif: {},
        loadingDetailPushNotif: true,
        loadingSendNotif: false
      }
    case 'GET_DATA_ALL_USER_PUSH_NOTIF':
      return {
        ...state,
        dataAllUser: action.dataAllUser,
        loadingDataAllUser: false,
        paramsUser: action.paramsUser,
        currentPageUser: action.currentPageUser,
        totalPagesUser: action.totalPagesUser
      }
    case 'GET_DATA_NEWS_PUSH_NOTIF':
      return {
        ...state,
        dataNews: action.dataNews
      }
    case 'GET_DATA_REWARD_PUSH_NOTIF':
      return {
        ...state,
        dataReward: action.dataReward
      }
    case 'GET_EFFECTED_USER_PUSH_NOTIF': 
      return {
        ...state,
        totalAffectedUser: action.totalAffectedUser,
        loadingEffectedUser: false
      }
    case 'ERROR_GET_EFFECTED_USER_PUSH_NOTIF': 
      return {
        ...state,
        totalAffectedUser: 0,
        loadingEffectedUser: false
      }
    case 'ERROR_GET_SEARCH_PUSH_NOTIF':
      return { 
        ...state,
        loadingData: false, 
        data: [] }
    case 'ERROR_GET_DATA_ALL_USER_PUSH_NOTIF':
      return { 
        ...state,
        loadingDataAllUser: false, 
        dataAllUser: [] }
    case 'ERROR_GET_PUSH_NOTIF':
      return { 
        ...state, 
        loadingDetailPushNotif: false }
    default:
      return { ...state }
  }
}
export default pushNotif
