// ** Initial State
const initialState = {
  loadingData: true,
  data: []
}

const reportTransaksiRapelSaldo = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING_DATA_REPORT_TRANSAKSI_RAPEL_SALDO':
      return {
        ...state,
        data: [],
        loadingData: true
      }
    case 'GET_DATA_REPORT_TRANSAKSI_RAPEL_SALDO':
      return {
        ...state,
        data: action.data,
        loadingData: false,
        params: action.params
      }
    case 'ERROR_GET_DATA_REPORT_TRANSAKSI_RAPEL_SALDO':
      return { 
        ...state,
        loadingData: false, 
        data: [] }
    default:
      return { ...state }
  }
}
export default reportTransaksiRapelSaldo
