// ** Initial State
const initialState = {
  totalUser: {},
  loadingTotaluser: true,
  dataRegister: [],
  loadingDataRegis: true,
  dataActive: [],
  loadingActiveUser: true,
  dataPosting: []
}

const rapelAdminDashboard = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING_TOTAL_USER_RAPEL_ADMIN_DASHBOARD':
      return {
        ...state, 
        totalUser: {},
        loadingTotaluser: true 
      }
    case 'GET_TOTAL_USER_RAPEL_ADMIN_DASHBOARD':
      return {
        ...state,  
        totalUser: action.totalUser,
        loadingTotaluser: false 
      }
    case 'ERROR_GET_TOTAL_USER_RAPEL_ADMIN_DASHBOARD':
      return {
        ...state,  
        totalUser: {},
        loadingTotaluser: false 
      }
    case 'LOADING_REGISTER_USER_RAPEL_ADMIN_DASHBOARD':
      return {
        ...state, 
        dataRegister: {},
        loadingDataRegis: true 
      }
    case 'GET_REGISTER_USER_RAPEL_ADMIN_DASHBOARD':
      return {
        ...state,  
        dataRegister: action.dataRegister,
        loadingDataRegis: false 
      }
    case 'ERROR_GET_REGISTER_USER_RAPEL_ADMIN_DASHBOARD':
      return {
        ...state,  
        dataRegister: {},
        loadingDataRegis: false 
      }
      case 'LOADING_POSTING_RAPEL_ADMIN_DASHBOARD':
      return {
        ...state, 
        dataPosting: {},
        loadingPosting: true 
      }
    case 'GET_POSTING_RAPEL_ADMIN_DASHBOARD':
      return {
        ...state,  
        dataPosting: action.dataPosting,
        loadingPosting: false 
      }
    case 'ERROR_GET_POSTING_RAPEL_ADMIN_DASHBOARD':
      return {
        ...state,  
        dataPosting: {},
        loadingPosting: false 
      }
    case 'LOADING_ACTIVE_USER_RAPEL_ADMIN_DASHBOARD':
      return {
        ...state, 
        dataActive: {},
        loadingPosting: true 
      }
    case 'GET_ACTIVE_USER_RAPEL_ADMIN_DASHBOARD':
      return {
        ...state,  
        dataActive: action.dataActive,
        loadingPosting: false 
      }
    case 'ERROR_GET_ACTIVE_USER_RAPEL_ADMIN_DASHBOARD':
      return {
        ...state,  
        dataActive: {},
        loadingPosting: false 
      }
    default:
      return { ...state }
  }
}
export default rapelAdminDashboard
