// ** Initial State
const initialState = {
  loadingData: true,
  data: [],
  //currentPage: 1,
  totalPages: 1,
  params: {},
  search: null,
  status: null,
  page: 0,
  selectedPrice: {},
  loadingDetailPrice: true
}

const companyPriceReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING_DETAIL_COMPANY_PRICE_RAPEL': 
      return {
        ...state,
        selectedPrice: {},
        loadingDetailPrice: true
      }
    case 'LOADING_DATA_COMPANY_PRICE_RAPEL':
      return {
        ...state,
        data: [],
        loadingData: true
      }
    case 'GET_DATA_COMPANY_PRICE_RAPEL':
      return {
        ...state,
        data: action.data,
        loadingData: false
      }
    case 'ERROR_GET_DATA_COMPANY_PRICE_RAPEL':
      return {
        ...state, 
        data: [],
        loadingData: false
      }
    case 'POST_URL_PRICE_COMPANY_RAPEL':
      return {
        ...state,
        search: action.search,
        status: action.status,
        page: action.page
      }
    case 'GET_DETAIL_PRICE_COMPANY_RAPEL':
      return {
        ...state,
        selectedPrice: action.selectedPrice,
        loadingDetailPrice: false 
      }
    case 'ERROR_GET_DETAIL_PRICE_COMPANY_RAPEL':
      return {
        ...state,
        selectedPrice: {},
        loadingDetailPrice: false 
      }
    default:
      return { ...state }
  }
}
export default companyPriceReducer
