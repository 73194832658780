// ** Initial State
const initialState = {
  allData: [],
  data: [],
  //total: 1,
  params: {},
  id: null,
  selectedUser: {}
}

const users = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA_RAPEL':
      return { ...state, allData: action.data, statusUserData: action.statusUserData }
    case 'GET_DETAIL_USER_RAPEL':
      return {
        ...state,
        id: action.iduser,  
        selectedUser: action.selectedUser,
        statusUserData: action.statusUserData 
      }
    case 'GET_DATA_RAPEL':
      return {
        ...state,
        data: action.data,
        params: action.params,
        id: null,
        selectedUser: {}
      }
    case 'ADD_USER_RAPEL':
      return { ...state }
    case 'EDIT_USER_RAPEL':
      return { 
        ...state, 
        statusUserData: action.statusUserData }
    case 'ERROR_GET_SEARCH_USER_RAPEL':
      return { 
        ...state, 
        data: [] }
    case 'ERROR_GET_USER_RAPEL':
      return { 
        ...state, 
        statusUserData: action.statusUserData }
    case 'ERROR_EDIT_USER_RAPEL':
      return { 
        ...state, 
        statusUserData: action.statusUserData }
    default:
      return { ...state }
  }
}
export default users
