import { Ability } from '@casl/ability'
import { initialAbility } from './initialAbility'
import { accessControl } from '@src/service/access-control'

//Decrypt Data
import * as CryptoJS from 'crypto-js'
import { keyData } from '@key/key'

const decryptData = data => {
  const bytes = CryptoJS.AES.decrypt(data.replace(/['"]+/g, ''), keyData())
  const dataDencrypt = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
  return dataDencrypt
}

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
//const userData = JSON.parse(localStorage.getItem('userData'))
//const existingAbility = userData ? userData.ability : null
const userData = JSON.parse(localStorage.getItem('userData'))
const dataToDecript = userData ? decryptData(userData) : null
const roleUser = dataToDecript ? dataToDecript.userRole : null
const existingAbility = accessControl(roleUser)

export default new Ability(existingAbility || initialAbility)
